import { Col, Row } from 'antd';
import { Typography } from 'antd';
import { titleStyle, paragraphStyle, subTitleStyle } from '../style';

function GeneralServices(){

    const { Paragraph } = Typography;

    return (
        <div>
            <Row>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
                <Col xs={22} sm={20} md={18} lg={16} xl={14}>
                    <Typography style={paragraphStyle}>
                        <Paragraph style={titleStyle}>
                            General Services
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <ul>
                                <li>Crown and Bridge (Restorative and Cosmetic)</li>
                                <li>Veneers</li>
                                <li>Inlays / Onlays</li>
                                <li>Dental Implants</li>
                                <li>Endodontics / RCT</li>
                                <li>Treatment of TMJ Disorders / Headaches</li>
                                <li>Cosmetic Fillings</li>
                                <li>Teeth Whitening</li>
                                <li>Cleaning and Deep Cleaning</li>
                                <li>Wisdom Teeth Extraction</li>
                            </ul>
                        </Paragraph>

                        <Paragraph style={subTitleStyle}>
                            Preventive Care
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            Dental Hygiene is an important part of your overall healthcare regimen.  Our office is committed to providing you optimum cleanings and regular evaluations.   Detecting issues early is key to maintaining your oral health, saving your natural teeth, and preventing costly dental emergencies.  Having your teeth professionally cleaned and examined on a regular basis is one of important ways to practicing preventive dentistry. As always, prevention is the key in Dentistry.
                        </Paragraph>

                        <Paragraph style={subTitleStyle}>
                            Periodontal Therapy/ Deep Cleaning
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            Unfortunately, periodontal (gum) disease can lead to more than just an unsightly smile. It can cause loss of teeth and eventually, if left untreated, serious health problems. Gum disease can break down the support (bone) structures of the teeth, so that eventually, they will become loose. The problem is that until it gets quite severe, the person often has no symptoms. Sadly, the damage to the support structures of the teeth is irreversible. The good news is that if gum disease is caught in time, its progression can be halted and disease process can be reversed, and that is the key. To stop gum disease from progressing, we recommend periodontal therapy, or a deep cleaning. This gets rid of the bacteria in the gum pocket and provides the necessary conditions for healing to occur. Early warning signs of the disease include red, swollen, bleeding gums.
                        </Paragraph>

                        <Paragraph style={subTitleStyle}>
                            Tooth-Colored Composite Restorations
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            Unlike unaesthetic metal fillings, tooth-colored fillings, made of durable resin or porcelain, can be bonded to your teeth for a stronger, more natural-looking effect. 
                            <ul>
                                <li><b>Composite resins</b> are used to restore teeth with small decays. Decays will be removed and a small amount of resin will be bonded to the prepared cavity. The result is a strong, attractive restoration that will strengthen and protect your tooth.</li>
                                <li><b>Porcelain fillings</b> are used to restore teeth that have significant damage but that doesn't require a full crown. Porcelain fillings, also called inlays and onlays, are extremely durable, match existing tooth color, and protect the tooth.</li>
                            </ul>
                        </Paragraph>

                        <Paragraph style={subTitleStyle}>
                            Crown/ Bridge
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            A dental crown is a tooth-shaped "cap" that is placed over a tooth or covering the tooth to restore its shape and size, to improve the overall appearance of your smile, and to make your teeth much more durable. 
                            
                            A dental crown may be needed in the following situations:
                            <ul>
                                <li>To protect a weak tooth (decayed or tooth that undergone Root Canal Therapy) from breaking or to hold together parts of a cracked tooth </li>
                                <li>To restore an already broken tooth or a tooth that has been severely worn down </li>
                                <li>To cover and support a tooth with a large filling when there isn't a lot of tooth left </li>
                                <li>To hold a dental bridge in place</li>
                                <li>To cover misshaped or severely discolored teeth</li>
                                <li>To cover a dental implant</li>
                                <li>Correct chewing problems</li>
                                <li>Relieve pain from compromised teeth and return dental function</li>
                                <li>Beautify smiles </li>
                            </ul>
                        </Paragraph>

                        <Paragraph style={subTitleStyle}>
                            Endodontics/ Root Canal Therapy
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            All of our teeth have long, thin strands of dental nerve (pulp) which are branches of major nerves on your top and bottom Jaws. Due to physical or biological (cavities) damages, nerves can get damaged and can cause pain and swelling on surrounding area. Root Canal Therapy (RCT) is designed to save such damaged teeth, while getting rid of pain. During the procedure, an access is made into the tooth to the nerve, diseased nerve is removed and all canals are disinfected, then the tooth is permanently sealed with a crown.
                        </Paragraph>
                        
                        <Paragraph style={subTitleStyle}>
                         Wisdom teeth Removal
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                        According to the American Dental Association, you may need to have your wisdom teeth taken out if you have:
                        <ul>
                            <li>Pain in or near your wisdom teeth.</li>
                            <li>Repeated infection of soft tissue behind the lower last tooth.</li>
                            <li>Fluid-filled sacs called cysts.</li>
                            <li>Tumors</li>
                            <li>Damage to nearby teeth.</li>
                            <li>Gum disease.</li>
                            <li>Widespread tooth decay.</li>
                        </ul>
                        </Paragraph>

                    </Typography>
                </Col>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
            </Row>
        </div>
    )
}

export default GeneralServices;