import { Col, Row, Typography  } from 'antd';
import { titleStyle, paragraphStyle, subTitleStyle, containerStyle } from '../../style';

function ForDryMouthSuffers() {
    const { Paragraph } = Typography;

    return (
        <div>
            <Row style={containerStyle}>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
                <Col xs={22} sm={20} md={18} lg={16} xl={14}>
                    <Typography style={paragraphStyle}>
                        <Paragraph style={titleStyle}>
                            INFORMATION FOR DRY MOUTH (XEROSTOMIA) SUFFERERS                        
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>1. Causes of Dry Mouth: </span>                 
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <ul>
                                <li>Certain prescription drugs</li>
                                <li>Certain OTC medications</li>
                                <li>Tobacco</li>
                                <li>Mouth breathing</li>
                                <li>Anxiety</li>
                                <li>Depression</li>
                                <li>Alcohol</li>
                                <li>Caffeine</li>
                                <li>Spicy or salty foods</li>
                                <li>Breathing unusually dry, cold, dusty, or dirty air</li>
                                <li>Stress</li>
                                <li>Vitamin deficiencies</li>
                                <li>Not drinking enough water</li>
                                <li>Radiation and chemotherapy</li>
                                <li>Arthritis</li>
                                <li>Diabetes</li>
                                <li>Menopause</li>
                                <li>Sjogren's Syndrome</li>
                                <li>Lupus</li>
                                <li>Parkinson’s disease</li>
                            </ul>
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>2. What drugs and medications can cause dry mouth?</span>                 
                        </Paragraph>
                        
                        <Paragraph style={paragraphStyle}>
                            <ul>
                                <li>High blood pressure medicines</li>
                                <li>Antidepressants</li>
                                <li>Tranquilizers</li>
                                <li>Antihistamines</li>
                                <li>Anti-Parkinson agents</li>
                                <li>Antipsychotics</li>
                                <li>Decongestants</li>
                                <li>Narcotic pain relievers</li>
                                <li>Chemotherapy</li>
                                <li>Antidiarrheals</li>
                                <li>Bronchodilators</li>
                            </ul>
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>3. What problems might develop because of dry mouth?</span>                 
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <ul>
                                <li>Rapid development of tooth decay</li>
                                <li>Sore tissues</li>
                                <li>Worsening periodontal disease</li>
                                <li>Sore spots and ulcers under dentures</li>
                                <li>Difficulty wearing dentures</li>
                                <li>Mouth sores in non-denture wearers</li>
                                <li>Breath doesn’t feel as fresh</li>
                                <li>Difficulty chewing and swallowing normal foods</li>
                                <li>Difficulty tasting normal foods</li>
                                <li>Difficulty speaking</li>
                                <li>Burning sensation in the mouth</li>
                                <li>Dry or painful tongue</li>
                            </ul>
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>4. How can I avoid destruction from rapid tooth decay?</span>                 
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <ul>
                                <li><u>Avoid hidden sugars</u>, such as cough drops, lemon drops, hard candy, breath mints, chewing gum, soda pop, etc.  People with dry mouths can get literally dozens of cavities in a matter of a few months.</li>
                                <li>Use a <u>prescription fluoride</u> preparation.  These get more fluoride to the teeth than regular fluoride toothpaste.  They are available as a mouth-rinse, brush-on gel, or a gel applied in a custom-made mouth tray.  Ask your dentist for prescriptions, or construction of a custom fluoride tray.  MI Paste, Enamelon, Prevident and Gel-Kam are examples.</li>
                                <li>Maintain <u>superior oral hygiene</u> habits:  brush at least twice a day for two minutes per brushing, floss every day, and have your teeth cleaned/checked twice a year.</li>
                            </ul>
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>5. What will help relieve my dry mouth?</span>                 
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <ul>
                                <li>Increase water intake throughout the day, and take frequent small sips</li>
                                <li>Add moisture to the air with a humidifier</li>
                                <li>Protect lips with a balm</li>
                                <li>In cold weather, keep mouth and nose covered with a scarf when outdoors</li>
                                <li>Don’t use tobacco or alcohol</li>
                                <li>Avoid strong toothpastes</li>
                                <li>Avoid mouthwashes containing alcohol</li>
                                <li>Use only toothpastes and mouthwashes that don’t contain sodium laurel sulfate</li>
                                <li>Avoid spicy and salty foods, and products containing cinnamon, peppermint, or wintergreen</li>
                                <li>Take a daily multi-vitamin</li>
                                <li>Use oral products that contains Xylitol</li>
                                <li>Be sure your doctors and pharmacist are aware of all the medications you are taking</li>
                                <li>Ask your doctor if your medications can be adjusted or changed</li>
                                <li>Use a saliva substitute or mouth moisturizers</li>
                                <li>Use sugarless hard candies to stimulate saliva flow</li>
                            </ul>
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>6. What products are available?</span>                 
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <ul>
                               <li><b>Mouthwashes</b>: <em>Oasis, BetaCell Oral Rinse, Biotene Mouthwash, TheraBrite Oral Rinse, Tom’s of Maine Natural Mouthwash, Oral Moistening</em></li>
                               <li><b>Sprays</b>: <em>Moi-Stir Mouth Moistener spray (carboxymethylcellulose), Mouth Kote mouth spray, Salivart Oral Moisturizer spray (carboxymethylcellulose), Stoppers 4 Dry Mouth Spray (glycerin), Thayers Dry Mouth Spray (glycerin)</em></li>
                               <li><b>Toothpastes</b>: <em>Biotene Dry Mouth Toothpaste, Rembrandt Toothpaste for Canker Sore Sufferers, TheraBreath Oxygenating Toothpaste, Tom’s of Maine Natural Anticavity & Dry Mouth Toothpaste Gums, Mints, Biotene Dry Mouth Gum, XyliChew mints</em></li>
                               <li><b>Gels</b>: <em>Orajel Dry Mouth Moisturizing Gel, Oral Balance Mouth Moisturizing Gel by Biotene, Saliva Substitute by Roxane, Spry Dry Mouth Tooth Gel</em></li>
                               <li><b>Other</b>: <em>Biotene Dry Mouth Denture Grip, Moi-Stir Oral Swabsticks (carboxymethylcellulose), Salagen Tablets, 5mg (pilocarpine HCl); by prescription only </em></li>
                            </ul>
                        </Paragraph>

                    </Typography>
                </Col>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
            </Row>
        </div>
    )
}

export default ForDryMouthSuffers;