import React from 'react';
import { Row, Col, Typography, Card, Flex  } from 'antd';
import { paragraphStyle } from '../style';

import FormsDownload from '../components/formsDownload'

function NewPatientInformation() {

    const { Title, Paragraph } = Typography;

    const cardStyle = {
        width: "100%",
        marginTop: "10px"
    }

    return (
        <div style={{
            marginTop: "70px"
        }}>
            <Row>
                <Col xs={0} sm={3} md={3} lg={4} xl={5} />

                <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                    <Card 
                        title="Please download, print, and fill out the new 
                        patient form prior to your first appointment" 
                        bordered={true} 
                        headStyle={{textAlign: "center", backgroundColor: "#7CB342", color:"white"}}
                    >
                        <FormsDownload />
                    </Card>
                    
                </Col>

                <Col xs={0} sm={3} md={3} lg={4} xl={5}/>

            </Row>

            <Row style={{marginBottom: "10px"}}>
                <Col xs={0} sm={3} md={3} lg={4} xl={5}/>

                <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                    <Card style={cardStyle} title="Financial Policy" bordered={true} headStyle={{textAlign: "center", backgroundColor: "#7CB342", color:"white"}}>
                        <Paragraph style={paragraphStyle}>
                            At Eden Dental, before we complete non-routine treatment, we will provide a treatment plan with an estimate of all fees, estimated insurance coverage, and balances for you. Patients are responsible for payments at the time of treatment unless otherwise financial arrangement is made previously.


                            <br /> 
                            <br />
                            We accept cash, checks, and Credit card for payment. Also, 12 month interest free option with Care Credit is available.

                            <br /> 
                            <br />
                            <a href="http://Carecredit.com" target="_blank" rel="noopener noreferrer">
                            http://Carecredit.com
                            </a>
                            

                        </Paragraph>
                    </Card>
                    
                </Col>

                <Col xs={0} sm={3} md={3} lg={4} xl={5}/>
            </Row>

            <Row style={{marginBottom: "10px"}}>
                <Col xs={0} sm={3} md={3} lg={4} xl={5}/>

                <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                    <Card style={cardStyle} title="Dental Insurance" bordered={true} headStyle={{textAlign: "center", backgroundColor: "#7CB342", color:"white"}}>
                        <Paragraph style={paragraphStyle}>

                            At Eden Dental, we accept most dental insurance providers.  Please call our office to find out about your insurance.
                            <br /> 
                            <br />
                            <ul>
                                <li>Our staff will work with you to maximize your benefits and minimize your out-of-pocket costs, while providing the highest level of care.</li>
                                <li>Our staff will file your claim for you and let you know before treatment what your portion is, if there is any. We will always prioritize your dental health before anything else</li>
                            </ul>
                        
                            <br /> 
                            
                            <b><u>Things to know about dental Insurance:</u></b>
                            <br /> 
                            <ul>
                                <li>Most dental plans operate as agreements between your employer and an insurance provider. Together, they determine coverage levels and which procedures are covered. Often, you may have a dental care need that is not covered by your plan. We'll work with you to align treatments with your budget and prioritize necessary work.
                                <br />
                                <br />
                                While it's tempting to base dental decisions solely on insurance coverage, remember that your health is the most important thing. We'll partner with you and your insurance to ensure optimal oral health care.</li>
                                <br />
                               <li>At the end of each year:
                                    <ul>
                                    <li>Remember, your benefits expire at the end of the calendar year.</li>
                                    <li>Your benefits do not roll over. </li>
                                    <li>If you require significant dental work, it's wise to use your benefits before the year ends.  And carry on with fresh benefits in the New Year to make the most of them. We're here to assist you in saving money and maintaining a healthy mouth.</li>
                                    <li> Understanding dental insurance benefits can be puzzling. But our staff is here to help. We'll do our utmost to clarify your benefits, handle all claims, and inform you of any treatments not covered before proceeding.</li>
                                    </ul>
                                </li>
                                
                            </ul>

                        </Paragraph>
                    </Card>
                    
                </Col>

                <Col xs={0} sm={3} md={3} lg={4} xl={5}/>
            </Row>

            <Row>
                <Col xs={0} sm={3} md={3} lg={4} xl={5} />

                <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                    <Card style={cardStyle} title="Cancellation Policy" bordered={true} headStyle={{textAlign: "center", backgroundColor: "#7CB342", color:"white"}}>
                        <Paragraph style={paragraphStyle}>
                            We make every attempt to keep your appointment to their scheduled time. 
                            We understand the pressures of a busy schedule, and so all of our patient's schedules are important to us. 
                            While we understand that sometimes unexpected things keep you from arriving on time, 
                            you may be asked to reschedule as your treatment may infringe on other appointment times.
                            We will not compromise treatment to make up for lost time. 
                            It is unfair to other patients when you are late or do not show for your appointments. 
                            For that reason we require 24 hours notice for cancellations. 
                            A $50 fee will be charged for missed appointments without timely notification. 
                            If there becomes a pattern of missed visits we may find it necessary to dismiss you from our practice.
                        </Paragraph>
                    </Card>
                </Col>

                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
            </Row>
        </div>
    );
};

export default NewPatientInformation;