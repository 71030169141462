import { Col, Row, Typography, Image } from 'antd';
import { titleStyle, paragraphStyle, subTitleStyle } from '../style';
import smileDesign from '../images/SmileDesign2.png';
import veneerBeforeAfter from '../images/VeneerBeforeAfter.png';
import BleachingBeforeAfter from '../images/BleachingBeforeAfter.png';

function CosmeticDentistry(){

    const { Paragraph } = Typography;

    return (
        <div>
            <Row>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
                <Col xs={22} sm={20} md={18} lg={16} xl={14}>
                    <Typography style={paragraphStyle}>
                        <Paragraph style={titleStyle}>
                            Cosmetic Dentitry
                        </Paragraph>

                        <Paragraph style={subTitleStyle}>
                            Smile Design
                        </Paragraph>

                        <Row style={{paddingTop:"10px", paddingBottom:"20px"}}>
                            <Image
                                        // width={400}
                                        src={smileDesign}
                                        preview={false}
                                        alt="smile design"
                                    />
                        </Row>

                        <Paragraph style={paragraphStyle}>
                            For each cosmetic patient, we start with a smile design consultation. Our team understand that communication with the patient over what they expect, what they want their teeth to look like, is critical to their success. Cosmetic dentistry is a self-esteem service. As such, if the result is not pleasing to the patient, the treatment is a failure. In order for you to be happy with the result, you need to have strong input into the artistic planning of your smile. Our team will carefully listen to your wants and needs, evaluate your unique situation, and design a custom plan that will create the smile of your dreams, along with a stable, comfortable, healthy, and long lasting result.
                        </Paragraph>

                        <Paragraph style={subTitleStyle}>
                            Porcelain Veneers
                        </Paragraph>

                        <Row style={{paddingTop:"10px", paddingBottom:"20px"}}>
                            <Image
                                        src={veneerBeforeAfter}
                                        preview={false}
                                        alt="veneerBeforeAfter"
                                    />
                        </Row>

                        <Paragraph style={paragraphStyle}>
                            Porcelain veneers are thin shells of ceramic that bond directly to the front surfaces of the teeth. They are an ideal choice for improving your smile and have become increasingly popular due to their simplicity and versatility. Placing custom veneers requires a high degree of technical skill as well as attention to cosmetic detail. We place veneers routinely, and design each case individually to match and enhance the characteristics of each patient's smile.
                        </Paragraph>

                        <Paragraph style={subTitleStyle}>
                            Teeth Whitening
                        </Paragraph>

                        <Row style={{paddingTop:"10px", paddingBottom:"20px"}}>
                            <Image
                                        // width={400}
                                        src={BleachingBeforeAfter}
                                        preview={false}
                                        alt="BleachingBeforeAfter"
                                    />
                        </Row>

                        <Paragraph style={paragraphStyle}>
                            Your teeth become stained naturally as a result of the food and drinks that you consume. Dental whitening uses an ingredient to effectively lift the stain from deep within enamel. The result is a whiter, lighter, and more attractive smile. We offer in-office ZOOM whitening system, which can whiten your teeth up to 9 shades in about an hour. ZOOM whitening techniques are featured on TV's Extreme Makeover, and have a quick, easy way to whiten your teeth.
                        </Paragraph>
                    </Typography>
                </Col>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
            </Row>
        </div>
    )
}

export default CosmeticDentistry;