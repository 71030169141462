import { Col, Row, Typography, Image } from 'antd';
import { titleStyle, paragraphStyle, subTitleStyle } from '../style';
import ImplantBeforeAfter from '../images/ImplantBeforeAfter.png';

function ImplantDentistry(){

    const { Paragraph } = Typography;

    return (
        <div>
            <Row>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
                <Col xs={22} sm={20} md={18} lg={16} xl={14}>
                    <Typography style={paragraphStyle}>
                        <Paragraph style={titleStyle}>
                            Implant Dentistry
                        </Paragraph>

                        <Paragraph style={subTitleStyle}>
                            Dental Implants
                        </Paragraph>

                        <Row style={{paddingTop:"10px", paddingBottom:"20px"}}>

                                <Image
                                            // width={400}
                                            src={ImplantBeforeAfter}
                                            preview={false}
                                            alt="smile design"
                                        />
                      
                        </Row>

                        <Paragraph style={paragraphStyle}>
                            Dental implants are designed to provide a foundation for replacement teeth that look, feel and function like natural teeth. A person who has lost teeth regains the ability to eat virtually anything and can smile with confidence, knowing that teeth appear natural and that facial contours will be preserved.
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            The implants are man-made artificial tooth roots typically made from titanium, the same material used in artificial knee and hip replacements. The implants are placed into the jawbone where teeth are missing. The bone bonds with the titanium, creating a strong foundation for artificial teeth. In addition, implants can help preserve facial structure, preventing the bone deterioration that occurs when teeth are missing.
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            Implant can support a single crown, long span bridges and are often used when rehabilitating an entire dentition. They are considered long-term restorations and provide excellent results.
                        </Paragraph>
                        
                    </Typography>
                </Col>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
            </Row>
        </div>
    )
}

export default ImplantDentistry;