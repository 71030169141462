import { createContext, useState, useCallback, useEffect } from 'react';
import axios from 'axios';

const EdenWebContext = createContext();


function EdenWebContextProvider( {children}) {

    const metadata_url = 'https://ebozw0nttj.execute-api.us-east-1.amazonaws.com/prod';
    const api_url = "https://iax1q262bb.execute-api.us-east-1.amazonaws.com/prod"

    const api_key = process.env.API_KEY;

    const [metadata, setMetadata] = useState([]);

    const [notification, setNotification] = useState([]);
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    // Get Web Metadata
    const getWebMetadata = async () => {

        const response = await axios.get(metadata_url + "/metadata");
        const res_body = JSON.parse(response.data.body)
        setMetadata(res_body)
    }

    // Get Notification
    const getNotification = async () => {

        const response = await axios.get(api_url + "/notifgication",{
            headers: {
                'x-api-key': api_key
            }
        });
        setNotification(response.data)
    }

    // Presign URL for Form Downloads
    const getPresignedUrl_for_HIPAA = async () => {

        // Make an API call to get the pre-signed URL
        const response = await axios.post(api_url + "/presign", {
            form_name: "HIPAA-Form.pdf"
        },
        {
            headers: {
                'x-api-key': api_key
            }
        });
        
        window.open(response.data.presignedUrl, '_blank');
    }

    const getPresignedUrl_for_New_Patient_Form = async () => {

        // Make an API call to get the pre-signed URL
        const response = await axios.post(api_url + "/presign", {
            form_name: "NewPatientForm.pdf"
        },
        {
            headers: {
                'x-api-key': api_key
            }
        });

        window.open(response.data.presignedUrl, '_blank');
    }

    const post_request_information= async (body) => {

        // Make an API call to get the pre-signed URL
        const response = await axios.post(api_url + "/requestInformation", body,
        {
            headers: {
                'x-api-key': api_key
            }
        });
        return response
    }

    // Menu Selection
    const [currentMenu, setcurrentMenu] = useState('home');

    const setCurrentMenu = (selection) => {
        
        setcurrentMenu(selection);
    };

    const valueToShare = {
        windowWidth,
        currentMenu,
        metadata,
        notification,
        getWebMetadata,
        getNotification,
        setCurrentMenu,
        post_request_information,
        getPresignedUrl_for_HIPAA,
        getPresignedUrl_for_New_Patient_Form
    }

    return <EdenWebContext.Provider value={valueToShare}>
        {children}
    </EdenWebContext.Provider>

}

export { EdenWebContextProvider };
export default EdenWebContext;