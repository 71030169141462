import React from 'react';
import { Typography, Row, Col, Image } from 'antd';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import nj_3DCT from '../images/offices/nj_3DCT.jpg';
import nj_3Dprinter from '../images/offices/nj_3Dprinter.jpeg';
import nj_3shape1 from '../images/offices/nj_3shape1.jpg';
import nj_3shape2 from '../images/offices/nj_3shape2.jpg';
import { useMemo } from "react";
import useWebContext from '../hooks/use-webcontext';
import "../App.css";

function NJOffice() {

    const { windowWidth } = useWebContext();

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });

    const center = useMemo(() => ({ lat: 40.828860844903694, lng: -73.97271592883497 }), []);

    const { Title } = Typography;;
    const hoursStyle = {
        color: '#153849',
        fontSize: '13px',
        fontFamily: "Karla",
        letterSpacing: "4px",
        lineHeight: "1.6em"
    }
    const style = {fontFamily: "Quattrocento", letterSpacing: "2px"}

    const paddingValue = windowWidth < 780 ? "20px" : "20px";

    return (
        <div style={{marginTop: "20px"}}>

            <Row style={{paddingTop: paddingValue, paddingLeft: paddingValue}}>
                <Col flex="0 1 420px">
                    <Row>
                        <a href="https://www.3shape.com/" target="_blank" rel="noopener noreferrer">
                            <Image
                                width={200}
                                preview={false}
                                src={nj_3shape1}
                                alt="Eden Dental in Edgewater, NJ"
                            />
                            <Image
                                    width={200}
                                    preview={false}
                                    src={nj_3shape2}
                                    alt="Eden Dental in Edgewater, NJ"
                                />
                        </a>
                    </Row>

                    <Row style={{paddingTop:"10px", paddingBottom:"20px"}}>
                        <a href="https://sprintray.com/" target="_blank" rel="noopener noreferrer">
                            <Image
                                        width={400}
                                        src={nj_3Dprinter}
                                        preview={false}
                                        alt="Eden Dental in Edgewater, NJ"
                                    />
                        </a>
                    </Row>

                </ Col>

                <Col flex="1 1 400px">
                    <Row>
                        <Col xs={0} sm={1} md={2} lg={3} xl={4} />
                        <Col xs={24} sm={22} md={20} lg={18} xl={16}>
                            <span style={{
                                color: "#3c3c3c",
                                fontSize: "30px",
                                textAlign: "center",
                                fontFamily: "Quattrocento",
                                letterSpacing: "4px",
                                lineHeight: "1.6em",
                            }}>
                                {/* Visit Us */}
                            </span>
                            <br />

                            <span style={{
                                    color: "#153849",
                                    fontSize: "20px",
                                    textAlign: "center",
                                    fontFamily: "Quattrocento",
                                    letterSpacing: "43x"
                            }}>
                                986 River Rd, Edgewater, NJ 07020
                            </span>

                            <Row style={{paddingTop: "27px"}}>
                                <Col span={3}>
                                    <span style={style}>Mon</span>
                                </Col>
                                <Col span={21}>
                                    <span style={style}>2:00 PM ~ 8:00 PM</span>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "5px"}}>
                                <Col span={3}>
                                    <span style={style}>Tue</span>
                                </Col>
                                <Col span={21}>
                                    <span style={style}>2:00 PM ~ 8:00 PM</span>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "5px"}}>
                                <Col span={3}>
                                    <span style={style}>Wed</span>
                                </Col>
                                <Col span={21}>
                                    <span style={style}>10:00 AM ~ 6:00 PM</span>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "5px"}}>
                                <Col span={3}>
                                    <span style={style}>Thur</span>
                                </Col>
                                <Col span={21}>
                                    <span style={style}>Closed</span>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "5px"}}>
                                <Col span={3}>
                                    <span style={style}>Fri</span>
                                </Col>
                                <Col span={21}>
                                    <span style={style}>9:00 AM ~ 1:00 PM</span>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "5px"}}>
                                <Col span={3}>
                                    <span style={style}>Sat</span>
                                </Col>
                                <Col span={21}>
                                    <span style={style}>9:00 AM ~ 4:00 PM</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={0} sm={1} md={2} lg={3} xl={4} />
                    </Row>
                </ Col>
            </Row>

            <Row>
                <div className="App">
                    {!isLoaded ? (
                        <h1>Loading...</h1>
                    ) : (
                        <GoogleMap
                            mapContainerClassName="map-container"
                            center={center}
                            zoom={13}
                        >
                            <Marker position={{ lat: 40.828860844903694, lng: -73.97271592883497 }} />
                        </GoogleMap>
                    )}
                </div>
            </Row>
        </div>
    )
}

export default NJOffice;