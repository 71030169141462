import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = () => (
    <Helmet>
      <title>Eden Dental - Cosmetic and Implant Dentistry</title>
      <meta name="description" content="This is an example of an SEO optimized page." />
      <meta property="og:title" content="My SEO Optimized Page" />
      <meta property="og:description" content="This is an example of an SEO optimized page." />
      <meta property="og:image" content="https://example.com/image.jpg" />
    </Helmet>
  );

  export default SEO;

  