import { Col, Row, Typography  } from 'antd';
import { titleStyle, paragraphStyle, subTitleStyle, containerStyle } from '../../style';

function AfterCareWhitening() {
    const { Paragraph } = Typography;

    return (
        <div>
            <Row style={containerStyle}>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
                <Col xs={22} sm={20} md={18} lg={16} xl={14}>
                    <Typography style={paragraphStyle}>
                        <Paragraph style={titleStyle}>
                            HOME CARE INSTRUCTION AFTER IN-OFFICE WHITENING                       
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>1. FOOD AND DRINKS TO AVOID: </span> Over the next 1 week, avoid extreme temperatures (hot/cold water), Berries, ketchup, red sauces, tobacco products, coffee, mustard, soy sauce, cola, red wine, and tea. Avoid using colored toothpastes or gels for the first 48 hours, as well as colored mouthwash or home fluoride treatments. If you typically use PerioRx or any Chlorohexidine products in your daily homecare routine, wait one week before resuming their usage.                       
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>2. POST OPERATIVE SENSITIVITY: </span> Some patients may experience sensitivity, such as "zingers" or thermal sensitivity, 12 to 24 hours after in office whitening. Brushing with Sensodyne or Sensitivity Relief toothpaste may provide relief. Over-the-counter pain relievers like Advil, Motrin, or Extra-Strength Tylenol can also be used.                         
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>3. GUM IRRITATION AFTER WHITENING (WHITE SPOTS): </span> The healing process begins immediately after a soft tissue burn. Rinsing with warm salt water aids in this process. If a burning sensation occurs, massage a small drop of Vitamin-E oil into the tissue until the irritation subsides.                          
                        </Paragraph>

                    </Typography>
                </Col>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
            </Row>
        </div>
    )
}

export default AfterCareWhitening;