import { Col, Row, Image, Typography  } from 'antd';
import { titleStyle, paragraphStyle, subTitleStyle } from '../style';
import BiteAdjustmentBeforeAfter from '../images/BiteAdjustmentBeforeAfter.png';

function TMJTherapy(){

    const { Paragraph } = Typography;

    return (
        <div>
           <Row>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
                <Col xs={22} sm={20} md={18} lg={16} xl={14}>
                    <Typography style={paragraphStyle}>
                        <Paragraph style={titleStyle}>
                            TMJ Therapy and Bite Adjustment
                        </Paragraph>

                        <Paragraph style={subTitleStyle}>
                            TMJ Therapy
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            If you suffer from a painful, aching jaw, sore and sensitive teeth, a stiff neck, tension headaches, or morning headaches, talk to our team about TMJ (Temporomandibular Joint) Evaluation. We can do careful examination on your masticatory system (parts involving chewing) and come up with treatment plan that can help alleviate these problems.
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            According to the Academy of General Dentistry (AGD), there are several TMJ disorders, and any one of which can result from multiple causes. The most common factor contributing to TMJ disorder (TMD) is a bite problem affecting the joint itself. Interferences in the structure of individual teeth may force displacement of the lower jaw, leading the muscles to reposition the joints out of their sockets to force the upper and lower teeth to fit together. This abnormal functioning of Jaws can contribute to migraine headaches since muscles are involved.
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            Symptoms of TMJ disorder include the following:
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <ul>
                                <li>Pain or soreness in the jaw that is more prevalent in the morning or late afternoon</li>
                                <li>Clicking or popping when opening or closing the mouth</li>
                                <li>Swelling on the side of the face</li>
                                <li>Sensitive teeth in the absence of dental problems</li>
                                <li>An earache in the absence of an infection</li>
                                <li>Difficulty opening and closing the mouth and/or chewing</li>
                                <li>Upper and lower teeth that do not align properly (malocclusion)</li>
                                <li>Stiffness or “locked" feeling in the jaw when talking, yawning or eating</li>
                                <li>Jaw pain when chewing, biting or yawning</li>
                                <li>Recent changes to the bite</li>
                                <li>Frequently waking up with headaches or experiencing frequent tension headaches</li>
                            </ul>
                            </Paragraph>

                            <Paragraph style={subTitleStyle}>
                                Bite Adjustment
                            </Paragraph>

                            <Row style={{paddingTop:"10px", paddingBottom:"20px"}}>
                            <a href="https://sprintray.com/" target="_blank" rel="noopener noreferrer">
                                <Image
                                            // width={400}
                                            src={BiteAdjustmentBeforeAfter}
                                            preview={false}
                                            alt="BiteAdjustmentBeforeAfter"
                                        />
                            </a>
                        </Row>

                            <Paragraph style={paragraphStyle}>
                                Certain causes of TMD also may affect your bite and subsequently impact the longevity of any restorations. If our exam identifies problems such as wear, tooth mobility, muscle pain or other signs of malocclusion, your bite may need to be adjusted.
                            </Paragraph>

                            <Paragraph style={paragraphStyle}>
                                Ensuring a stable bite is essential to your oral health and the long-term durability and functionality of cosmetic restorations such as dental veneers, tooth implants or crowns. Additionally, maintaining a stable occlusion and a proper bite helps ensure that your upper and lower teeth will come into contact in the most comfortable and pain-free manner possible, without unnecessary force that could lead to headaches or fractured restorations.
                            </Paragraph>

                        </Typography>
                </Col>
                
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
            </Row>
        </div>
    )
}

export default TMJTherapy;