import { Col, Row } from 'antd';
import { Typography, Divider, List } from 'antd';
import { titleStyle, paragraphStyle, componentStyle, subTitleStyle } from '../style';


function OfficePolicies() {

    const { Title, Paragraph } = Typography;

    return (
        <div>
            <Row style={{
                margin: "1px 0px"
            }}>
                <Col flex="0 1 500px">
                    <Title level={3} style={{color: '#05202e', padding: "50px", textAlign: "center"}}>
                        Cancellation Policy
                    </Title>

                </Col>

                <Col flex="1 1 600px" style={{backgroundColor: "#47b1b5"}}>
                    <Title level={4} style={{color: 'white', padding: "30px", textAlign: "left"}}>
                        We make every attempt to keep your appointment to their scheduled time. 
                        We understand the pressures of a busy schedule, and so all of our patient's schedules are important to us. 
                        While we understand that sometimes unexpected things keep you from arriving on time, 
                        you may be asked to reschedule as your treatment may infringe on other appointment times.
                        We will not compromise treatment to make up for lost time. 
                        It is unfair to other patients when you are late or do not show for your appointments. 
                        For that reason we require 24 hours notice for cancellations. 
                        A $50 fee will be charged for missed appointments without timely notification. 
                        If there becomes a pattern of missed visits we may find it necessary to dismiss you from our practice.
                    </Title>
                </Col>

            </Row>

            <Row style={{
                margin: "1px 0px"
            }}>
                <Col flex="0 1 500px">
                    <Title level={3} style={{color: '#05202e', padding: "50px", textAlign: "center"}}>
                        Financial Policy
                    </Title>
                    
                </Col>
                <Col flex="1 1 300px" style={{backgroundColor: "#47b1b5"}}>
                    <Title level={4} style={{color: 'white', padding: "30px", textAlign: "left"}}>
                        We accept most major PPO/PDP insurance plans.

                        <ul>
                            <li>Aetna</li>
                            <li>Ameritas</li>
                            <li>Cigna</li>
                            <li>Connection Dental</li>
                            <li>DentaMax</li>
                            <li>Guardian</li>
                            <li>MetLife</li>
                            <li>MetLife</li>
                            <li>Major PPO Plans- If you are not sure, please call our office to find out about whether it is accepted by our office.</li>
                        </ul>
                    </Title>

                </Col>

            </Row>

            <Row style={{
                margin: "0px 0px"
            }}>

                <Col span={24} style={{backgroundColor: "#ff8467", padding: "30px" }}>
                    <Title level={4} style={{color: 'white', padding: "30px", textAlign: "center"}}>
                        We highly recommend you familiarize yourself with all of your insurance plans restrictions 
                        and benefits. We will make sure we provide as much information as we can, but remember your plan 
                        is a contract with you and your company.

                        <br /> 
                        <br /> 
                        Before we complete non-routine treatment, we will provide a treatment plan with an estimate 
                        of all fees, estimated insurance coverage, and balances for you. Patients are responsible for 
                        payments at the time of treatment unless otherwise financial arrangement is made previously.

                        <br /> 
                        <br /> 
                        We accept cash, checks, and Credit card for payment. Also, 12 month interest free option with Care Credit is available.
                    </Title>

                </Col>

            </Row>
        </div>
    )
}

export default OfficePolicies;