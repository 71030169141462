import { Col, Row, Typography  } from 'antd';
import { titleStyle, paragraphStyle, subTitleStyle, containerStyle } from '../../style';

function AfterCareRootCanal() {
    const { Paragraph } = Typography;

    return (
        <div>
            <Row style={containerStyle}>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
                <Col xs={22} sm={20} md={18} lg={16} xl={14}>
                    <Typography style={paragraphStyle}>
                        <Paragraph style={titleStyle}>
                            HOME CARE INSTRUCTION AFTER - ROOT CANAL                        
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>1. EATING: </span> Please refrain from chewing or eating on the side of your mouth that has been treated while it is still numb. Whenever possible, try to chew on the opposite side until a permanent crown is placed, as the tooth may still be weak and susceptible to fracture. Avoid crunchy or hard foods that could potentially cause the weakened tooth to fracture.                        
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>2. PAIN MEDS: </span> After the treatment, area can be sore for 2-3 days. It's recommended to take pain relief medication as soon as you can before the anesthesia wears off. Ibuprofen (e.g., Advil, Motrin) is highly effective. You can take 2-3 tablets of Ibuprofen every 6-8 hours for first 2 days to manage soreness from the treatment and take 2 tabs as needed after 2 days. If ibuprofen is not suitable due to a medical condition or gastrointestinal disorder, acetaminophen (e.g., Tylenol) can be taken as an alternative, 2 tabs every 8 hours for first 2 days and take 2 tabs as needed after 2 days.                         
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>3. GUM DISCOMFORT: </span> Your gum may feel tender following the procedure, and the injection site may be sore. Rinsing your mouth 2-3 times a day with 1 teaspoon of warm salt water can help alleviate discomfort. Tylenol or Advil can also be effective in reducing tenderness.                         
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>4. SMOKING: </span> Avoid smoking for 24-48 hours as it can delay tissue healing.                     
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>5. EXERCISE: </span> Refrain from strenuous exercise for 24 hours.                       
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>6. FOLLOW-UP VISIT: </span> A putty-like material is used to seal your tooth after root canal treatment. If left in for too long, it may permit bacteria to enter the treated tooth, requiring further treatment. It's recommended to return to the clinic within 3–4 weeks after the root canal treatment to prepare your tooth for a crown and build-up. Delay in obtaining your final restoration, such as a crown, may lead to tooth fracture or possible loss. 
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>7. PERSISTENT/SEVERE SENSITIVITY TO HOT/COLD/THROBBING PAIN: </span> Please contact us at 201-224-6401 for further evaluation.
                        </Paragraph>
                    </Typography>
                </Col>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
            </Row>
        </div>
    )
}

export default AfterCareRootCanal;