import { Col, Row, Typography  } from 'antd';
import { titleStyle, paragraphStyle, subTitleStyle, containerStyle } from '../../style';

function AfterCareCrown() {
    const { Paragraph } = Typography;

    return (
        <div>
            <Row style={containerStyle}>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
                <Col xs={22} sm={20} md={18} lg={16} xl={14}>
                    <Typography style={paragraphStyle}>
                        <Paragraph style={titleStyle}>
                            HOME CARE INSTRUCTION AFTER - CROWN / TEMPORARY CROWN                        
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>1. EATING: </span> Refrain from eating until the anesthesia wears off. Be cautious to avoid accidentally chewing on your tongue, lips, or cheeks due to numbness.                         
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>2. HIGH/TALL BITE, OFF-BALANCED BITE: </span> If you notice any issues with your bite, please contact us at 201-224-6401 for necessary adjustments.                         
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>3. SENSITIVITY TO HOT AND COLD: </span> It's common to experience sensitivity to hot and cold after the procedure. Symptoms typically improve within a few days, but in some cases, it may take a few weeks. As long as the sensitivity continues to decrease, there's usually no need for concern.                          
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>4. GUM DISCOMFORT: </span> Your gums may feel tender after the procedure, and the injection site might be sore. Rinsing your mouth 2-3 times a day with 1 teaspoon of warm salt water can help alleviate discomfort. Tylenol or Advil can also be effective in reducing tenderness.                      
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>5. PAIN MEDS: </span> Take an anti-inflammatory pain reliever if needed (e.g., Tylemol/Advil).                         
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>6. JAW PAIN: </span> Apply ice packs or cold compresses to both the muscles and joints for 5 minutes, followed by a hot compress for 15 minutes to the same areas. This can be repeated several times throughout the day.                         
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>7. TEMPORARY CROWN LOOSENED, CAME OFF: </span>                         If your temporary crown becomes loose or comes off, please contact us at 201-224-6401 to have it re-cemented. If it occurs over the weekend, you can check local pharmacies (e.g., Walgreens, Rite-aid) or grocery stores (e.g., Ralphs) for an Over-the-Counter cement. When re-cementing:
                        
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <ul>
                                <li>Clean both your tooth and the inside of the temporary crown, then dry the temporary crown before reapplying.</li>
                                <li>Ensure the temporary crown is placed on the tooth with the correct orientation.</li>
                                <li>Apply temporary material (e.g., cement) inside the temporary crown and place it on your tooth. Bite down on a rolled tissue paper (directly placed on the crown) for 5-10 minutes until the material sets.</li>
                                <li>Toothpaste can serve as temporary cement until you can see a dentist.</li>
                                <li>PERSISTENT/SEVERE SENSITIVITY TO HOT/COLD/THROBBING PAIN: If you experience persistent or severe sensitivity or throbbing pain, please call us at 201-224-6401 for further evaluation.</li>
                            </ul>
                        </Paragraph>


                    </Typography>
                </Col>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
            </Row>
        </div>
    )
}

export default AfterCareCrown;