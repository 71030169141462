import { useEffect, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Layout } from 'antd';
import useWebContext from './hooks/use-webcontext';
import Home from './pages/home';
import NewPatientInformation from './pages/newPatientInformation';
import OfficePolicies from './pages/officePolicies';
import CompleteDentistry from "./pages/whatIsCompleteDentistry";
import OurOffice from "./pages/offices/ourOffice";
import NYOfficePage from "./pages/offices/nyOffice";
import NJOfficePage from "./pages/offices//njOffice";
import CosmeticDentistry from "./pages/cosmeticDentistry";
import ImplantDentistry from "./pages/implantDentistry";
import FAQ from "./pages/FAQ";
import MeetDrLee from './pages/meetDrLee';
import TMJTherapy from "./pages/TMJTherapy";
import EdenFooter from "./components/footer";
import EdenHeader from "./components/header";
import EdenWebContext from './contexts/index';
import GeneralServices from "./pages/generalServices";
import AfterCareCrown from './pages/homeCare/afterCareCrown';
import AfterCareDeepCleaning from './pages/homeCare/afterCareDeepCleaning';
import AfterCareDenture from './pages/homeCare/afterCareDenture';
import AfterCareFilling from './pages/homeCare/afterCareFillings';
import AfterCareOcclusalGuard from './pages/homeCare/afterCareOcclusalGuard';
import AfterCareRootCanal from './pages/homeCare/afterCareRootCanal';
import AfterCareWhitening from './pages/homeCare/afterCareWhitening';
import BeforeAfterSurgery from './pages/homeCare/beforeAfterSurgery';
import ForDryMouthSuffers from './pages/homeCare/forDryMouthSuffers';
import FormPage from "./pages/form";
import SEO from  './components/metadata'
import './App.css'; 

function App() {

    // Fetch the web metadata for initial loads.
    const {getWebMetadata} = useContext(EdenWebContext);

    useEffect(() => {
        getWebMetadata();
    }, []);


    const { currentMenu } = useWebContext();
    const { Footer } = Layout;

    const layoutStyle = {
        direction: "vertical",
        margin: '0 auto',
        backgroundColor: '#05202e' 
      };

    const backgroundColor = {
        backgroundColor: 'white',
    }

    return (
        <div style={layoutStyle}>
            <SEO />
            <Layout style={backgroundColor}>
                <Router>
                    <EdenHeader />
                    <main>
                        <Routes>
                            <Route exact path='/' element={< Home />}></Route>
                            <Route exact path='/home' element={< Home />}></Route>
                            <Route exact path="/scheduleForm" element={<Home />} />
                            <Route exact path='/meet_dr_lee' element={< MeetDrLee />}></Route>
                            <Route exact path='/our_office' element={< OurOffice />}></Route>
                            <Route exact path='/nj_office' element={< NJOfficePage />}></Route>
                            <Route exact path="/nj_office/scheduleForm" element={<NJOfficePage />} />
                            <Route exact path='/ny_office' element={< NYOfficePage />}></Route>
                            <Route exact path="/ny_office/scheduleForm" element={<NYOfficePage />} />
                            <Route exact path='/new_patient_information' element={< NewPatientInformation />}></Route>
                            <Route exact path='/office_policies' element={<OfficePolicies/>}></Route>
                            <Route exact path='/faq' element={<FAQ/>}></Route>
                            <Route exact path='/general_services' element={<GeneralServices/>}></Route>
                            <Route exact path='/cosmetic_dentistry' element={<CosmeticDentistry/>}></Route>
                            <Route exact path='/complete_dentistry' element={<CompleteDentistry/>}></Route>
                            <Route exact path='/implant_dentistry' element={<ImplantDentistry/>}></Route>
                            <Route exact path='/tmj_therapy' element={<TMJTherapy/>}></Route>
                            <Route exact path='/after_care_crown' element={< AfterCareCrown />}></Route>
                            <Route exact path='/after_care_deep_cleaning' element={< AfterCareDeepCleaning />}></Route>
                            <Route exact path='/after_care_denture' element={< AfterCareDenture />}></Route>
                            <Route exact path='/after_care_fillings' element={< AfterCareFilling />}></Route>
                            <Route exact path='/after_care_occlusal_guard' element={< AfterCareOcclusalGuard />}></Route>
                            <Route exact path='/after_care_root_canal' element={< AfterCareRootCanal />}></Route>
                            <Route exact path='/after_care_whitening' element={< AfterCareWhitening />}></Route>
                            <Route exact path='/before_after_surgery' element={< BeforeAfterSurgery />}></Route>
                            <Route exact path='/for_dry_mouth_suffers' element={< ForDryMouthSuffers />}></Route>
                            <Route exact path='/formPage' element={< FormPage />}></Route>
                        </Routes>
                    </main>
                    </Router>
                <Footer style={{backgroundColor:"#263238", marginTop: "1px"}}>
                    <EdenFooter />
                </Footer>
            </Layout>
           
        </div>
        
    )
}

export default App;