import React, { useState, useContext, useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import { Row, Col, Button, Modal, Avatar, Form, Input, Alert, DatePicker, Select  } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import {  useNavigate } from 'react-router-dom';
//import homeBackgroundMainImage from '../images/home_page_sample_office_background.jpeg';
import homeBackgroundMainImage from '../images/frontPageImage.jpg';
import MAIN from '../images/MAIN.jpg';
import { textStyle } from '../style'
import EdenWebContext from  '../contexts/index'

const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 14,
    },
};

const { Option } = Select;

function Home() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const { post_request_information } = useContext(EdenWebContext);
    const [requestInfoSubmitted, setRequestInfoSubmitted] = useState(false);
    const [form] = Form.useForm();
    const { notification  } = useContext(EdenWebContext);

    const navigate = useNavigate();

    const showModal = () => {
        navigate('/scheduleForm', { replace: true });
        setIsModalOpen(true);
    };

    const handleOk = () => {

        form.validateFields().then((values) => {

            form.resetFields();
            setIsModalOpen(false);
            post_request_information(values)
            setRequestInfoSubmitted(true)

          }).catch((errorInfo) => {
            setRequestInfoSubmitted(false)
          });
          navigate('/home', { replace: true });
    };

    const handleCancel = () => {
        navigate('/', { replace: true });
        form.resetFields();
        setIsModalOpen(false);
        setRequestInfoSubmitted(false)
    };

    const connectUsBackgroundImageStyle = {
        backgroundImage: `url(${MAIN})`,
        backgroundSize: 'cover', // Optional: Adjust the background image size ('cover' will cover the entire element)
        backgroundRepeat: 'no-repeat', // Optional: Prevent background image from repeating
        //height: '200px', // Set the height of the component
        width: '100%',
        height: '100%',
        backgroundPosition: 'center',

    };

    const connectTextStyle = {
        color: "white",
        fontSize: "50px",
        textAlign: "center",
        paddingTop: "200px",
        fontFamily: "sarabun",
        letterSpacing: "4px",
        lineHeight: "1.6em"
    }

    const modalStyle = {
        color: "white",
        fontSize: "60px",
        textAlign: "center",
        paddingTop: "300px",
        paddingBottom: "30px"
    }

    const modelButtonStyle = {
        background: "none",
        border: "none",
        fontSize: "30px",
        color:"white", /* Button text color */
        padding: "0px", /* Optional: Remove padding to make the button text closer to the container edge */
    }

    const imageContainerStyle ={
        width: '100%', // Set the width of the container to 100%
        // height: '400px', // Set the height of the container
       // background: 'linear-gradient(to right, white 60%, #FFFDE7 40%)', // Apply linear gradient with orange on the left 30% and another color on the rest
    }

    const blueTextStyle = {
        color: '#E65100', // Text color,
        paddingRight: '15px',
        WebkitTextStroke: '1px #E65100'
      };

    return (
        <div style={{backgroundColor: "white"}}>    

            <Row>
                <Col span={24} style={connectUsBackgroundImageStyle}>

                    { notification.display ? 
                        <Row> 
                            <Col span={24} style={{padding:"20px 0px"}}>
                                <Alert
                                    banner
                                    message={
                                        <Marquee pauseOnHover gradient={false} style={{fontFamily: "Quattrocento", letterSpacing: "1.5px"}}>
                                            {notification.message}
                                        </Marquee>
                                    }
                                    style={{ 
                                        fontSize: "16px", 
                                        backgroundColor: "#E1F5FE", 
                                        color: "#01579B", 
                                        padding:"10px"}}
                                />
                            </Col>
                        </Row> : null
                    }      

                    {/* <Row >
                        <Col span={24} style={connectTextStyle}>
                            EDEN DENTAL
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} style={{fontSize: "25px", color: "white", textAlign: "center",paddingTop: "20px"}}>
                            comprenhensive & family-oriented
                        </Col>
                    </Row> */}

                    <Row>
                        <Col span={24} style={modalStyle}>
                            <Button style={modelButtonStyle} onClick={showModal}>
                                <span style={blueTextStyle}>schdule an appointment</span> 
                                <Avatar
                                    style={{
                                        backgroundColor: 'white',
                                        color: "#f86d4c"
                                    }}
                                    icon={<ArrowRightOutlined />}
                                />
                            </Button>
                            <Modal 
                                    title="REQUEST AN APPOINTMENT" 
                                    open={isModalOpen} 
                                    onOk={handleOk} 
                                    onCancel={handleCancel}
                                    okText="Submit"
                                >    
                                    <Form
                                        {...layout}
                                        form={form}
                                        name="control-hooks"
                                        style={{
                                            maxWidth: 800,
                                            paddingTop: "30px"
                                        }}
                                    >
                                        <Form.Item
                                            name="name"
                                            label="Name"
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please input your name.'
                                            }
                                            ]}
                                        >
                                            <Input placeholder="Name" />
                                        </Form.Item>

                                        <Form.Item
                                            name="phone"
                                            label="Phone"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your phone number.'
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Phone Number" />
                                        </Form.Item>

                                        <Form.Item 
                                            name="location" 
                                            label="Location" 
                                            rules={[
                                                { 
                                                    required: true ,
                                                    message: 'Please a preferred location.'
                                                }]}
                                        >
                                            <Select
                                                placeholder="Select a preferred location"
                                                allowClear
                                            >
                                            <Option value="Nanuet, NY">Nanuet, NY</Option>
                                            <Option value="Edgewater , NJ">Edgewater , NJ</Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name="email"
                                            label="Email"
                                            rules={[
                                                {
                                                  type: 'email',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Email"/>
                                        </Form.Item>

                                        <Form.Item
                                            name="dateTime"
                                            label="Prefered Date"
                                        >
                                            <DatePicker />
                                        </Form.Item>
                                        
                                    </Form>

                                </Modal> 
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row style={{width: '100%'}}>
                <Col span={24} style={{
                            color: "#3c3c3c",
                            fontSize: "26px",
                            textAlign: "center",
                            paddingTop: "50px",
                            fontFamily: "Quattrocento",
                            letterSpacing: "4px",
                            lineHeight: "1.6em"
                }}>
                    <p> ALL THE MORE REASON TO SMILE </p>
                </Col>
            </Row>

            <Row style={{width: '100%', margin: "20px"}}>
                <Col span={24} style={{
                            color: "#153849",
                            fontSize: "30px",
                            textAlign: "center",
                            fontFamily: "Quattrocento",
                            letterSpacing: "4px"
                }}>
                    <span>WELCOME TO EDEN DENTAL</span>
                </Col>
            </Row>

            <Row style={{width: '100%', margin: "30px 0px"}}>
                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
                <Col xs={24} sm={20} md={18} lg={16} xl={14} style={textStyle}>
                    	
                    Welcome to Eden Dental, where we take pride in being a comprehensive, family-oriented practice specializing in cosmetic and implant dentistry. 
                    We appreciate your visit to our website, designed to offer you comprehensive insights into our practice.
                    <br />
                    <br />
                    Feel free to reach out to us anytime with additional questions or for more information to help you make an informed decision about choosing us for your dental care needs.
                    <br />
                    <br />
                    We eagerly anticipate the chance to introduce you to the Eden Dental team and deliver exceptional quality in dental care. Your oral health is our priority, and we're here to ensure a positive and comfortable experience for you and your family.
                </Col>
                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
            </Row>

        </div>
    )
}

export default Home;