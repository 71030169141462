import { Col, Row, Typography  } from 'antd';
import { titleStyle, paragraphStyle, subTitleStyle, containerStyle } from '../../style';

function AfterCareDeepCleaning() {
    const { Paragraph } = Typography;

    return (
        <div>
            <Row style={containerStyle}>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
                <Col xs={22} sm={20} md={18} lg={16} xl={14}>
                    <Typography style={paragraphStyle}>
                        <Paragraph style={titleStyle}>
                            HOME CARE INSTRUCTION AFTER - DEEP CLEANING/ ANTIBACTERIAL MOUTHWASH/ ARESTIN                        
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>1. EATING: </span> Wait until the anesthesia wears off before eating. Be mindful to avoid accidentally   biting your tongue, lips, or cheeks due to numbness. Avoid hard, crunchy foods like tortilla chips, potato chips, popcorn, or seeds for the next few days.                         
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>2. SMOKING: </span> Refrain from smoking for 24-48 hours as it can delay tissue healing.                       
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>3. MILD SENSITIVITY TO COLD: </span>  Use a desensitizing toothpaste or fluoride gel (e.g., MI Paste, Prevident, or Enamelon) at least four times a day for 1 to 2 weeks. Maintaining clean teeth helps reduce sensitivity.                      
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>4. GUM DISCOMFORT: </span>  Your gums may feel tender after the procedure. The injection site may also be sore. Rinse your mouth 2-3 times a day with 1 teaspoon of warm salt water to ease discomfort. Tylenol or Advil can help alleviate tenderness.                   
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>5. JAW PAIN: </span>  Apply ice packs or cold compresses to the muscles and joints for 5 minutes, followed by a hot compress for 15 minutes, several times a day. 
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>6. ANTIBIOTICS: </span>  If prescribed, take antibiotics with food as directed. 
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>7. ANTIBACTERIAL MOUTHWASH (CHLORHEXIDINE RINSE): </span>  Rinse your mouth twice a day for 1 week. Swish for 30 seconds and spit out. Temporary teeth staining may occur due to the lingering medication.
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>8. ARESTIN: </span> If treated with Arestin: 
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <ul>
                                <li>Avoid touching treated areas</li>
                                <li>Wait 12 hours before you start brushing</li>
                                <li>Wait 10 days before using floss, toothpicks, or other devices designed to clean between the treated teeth</li>
                                <li>Avoid food that could irritate your gums for 1 week</li>
                                <li>Avoid chewing gum or sticky foods</li>
                                <li>After 10 days, resume cleaning between the treated teeth on a daily basis</li>
                            </ul>
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>9. PERSISTENT DISCOMFORT/SWELLING/PAIN: </span> Contact the office at 201-224-6401for further instructions. 
                        </Paragraph>

                    </Typography>
                </Col>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
            </Row>
        </div>
    )
}

export default AfterCareDeepCleaning;