import React from 'react';
import { Typography, Row, Col, Image } from 'antd';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import bluewaitingroom from '../images/sample_offices/bluewaitingroom.jpg';
import examroom from '../images/sample_offices/examroom.jpg';
import neonsign from '../images/sample_offices/neonsignvillage.jpg';
import waitingroom from '../images/sample_offices/waitingroom.jpg';
import nyOfficeImage from '../images/ny_office_image.jpg'
import { useMemo } from "react";
import useWebContext from '../hooks/use-webcontext';
import "../App.css";


function NYOffice() {

    const { windowWidth } = useWebContext();

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });

    const center = useMemo(() => ({ lat: 41.10123068800376, lng: -74.02872564068183 }), []);

    const { Title } = Typography;;
    const hoursStyle = {
        color: '#153849',
        fontSize: '13px',
        fontFamily: "Karla",
        letterSpacing: "4px",
        lineHeight: "1.6em"
    }
    const style = {fontFamily: "Quattrocento", letterSpacing: "2px"}

    const paddingValue = windowWidth < 780 ? "20px" : "20px";

    return (
        <div style={{marginTop: "20px", marginBottom: "30px"}}>

            <Row style={{paddingTop: paddingValue, paddingLeft: paddingValue}}>
                <Col flex="0 1 420px">
         
                    <Row style={{paddingBottom: "20px"}}>
                        <Image
                            src={nyOfficeImage}
                            preview={false}
                            alt="Eden Dental in Nanuet, NY"
                        />
                    </Row>
                  
                </ Col>

                <Col flex="1 1 400px">
                    <Row>
                        <Col xs={0} sm={1} md={2} lg={3} xl={4} />
                        <Col xs={24} sm={22} md={20} lg={18} xl={16}>
                            <span style={{
                                color: "#3c3c3c",
                                fontSize: "30px",
                                textAlign: "center",
                                fontFamily: "Quattrocento",
                                letterSpacing: "4px",
                                lineHeight: "1.6em",
                            }}>
                               
                            </span>
                            <br />

                            <span style={{
                                    color: "#153849",
                                    fontSize: "20px",
                                    textAlign: "center",
                                    fontFamily: "Quattrocento",
                                    letterSpacing: "43x",
                                    
                            }}>
                                55 Old Turnpike Road, Suite #501
                                <br />                                
                                Nanuet, NY 10954
                            </span>

                            <Row style={{paddingTop: "27px"}}>
                                <Col span={3}>
                                    <span style={style}>Mon</span>
                                </Col>
                                <Col span={12}>
                                    <span style={style}>9:00 AM ~ 1:00 PM</span>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "5px"}}>
                                <Col span={3}>
                                    <span style={style}>Tue</span>
                                </Col>
                                <Col span={21}>
                                    <span style={style}>9:00 AM ~ 1:00 PM</span>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "5px"}}>
                                <Col span={3}>
                                    <span style={style}>Wed</span>
                                </Col>
                                <Col span={12}>
                                    <span style={style}>Closed</span>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "5px"}}>
                                <Col span={3}>
                                    <span style={style}>Thu</span>
                                </Col>
                                <Col span={21}>
                                    <span style={style}>9:00 AM ~ 6:00 PM</span>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "5px"}}>
                                <Col span={3}>
                                    <span style={style}>Fri</span>
                                </Col>
                                <Col span={21}>
                                    <span style={style}>2:00 PM ~ 7:00 PM</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={3}>
                                </Col>
                                <Col span={21}>
                                    <span style={style}> (Open every other Friday)</span>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "5px"}}>
                                <Col span={3}>
                                    <span style={style}>Sat</span>
                                </Col>
                                <Col span={21}>
                                    <span style={style}>Closed</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={0} sm={1} md={2} lg={3} xl={4} />
                    </Row>
                </ Col>
            </Row>

            <Row>
                <div className="App">
                    {!isLoaded ? (
                        <h1>Loading...</h1>
                    ) : (
                        <GoogleMap
                            mapContainerClassName="map-container"
                            center={center}
                            zoom={13}
                        >
                            <Marker position={{ lat: 41.10123068800376, lng: -74.02872564068183 }} />
                        </GoogleMap>
                    )}
                </div>
            </Row>

        </div>
    )
}

export default NYOffice;