export const mainStyle = {
    color: '#05202e'
}

export const titleStyle = {
    padding: '10px 0px 0px 0px',
    fontFamily: 'sarabun',
    fontSize: '20px',
    color: '#1B5E20'
}

export const subTitleStyle = {
    padding: '10px 0px 0px 0px',
    fontFamily: 'sarabun',
    fontSize: '17px',
    color: '#BF360C'
}

export const paragraphStyle = {
    fontSize: '16px',
    color: '#05202e',
    fontFamily: 'Raleway',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: '0em',
    lineHeight: '1.8em'
}

export const componentStyle = {
    marginLeft: "100px",
    marginRight: "100px",
    padding: '20px',
   //backgroundColor: 'white',
};

export const gutter_box = {
    padding: '15px'
  }

export const textStyle = {
    color: "#153849",
    fontSize: "1.1rem",
    textAlign: "center",
    padding: "10px 00px",
    fontFamily: "Quattrocento",
    wordSpacing: "0.25px",
    lineHeight: "1.6em",
    letterSpacing: "0.25px"
}

export const containerStyle = {
    marginTop: "30px",
    marginBottom: "30px"
}
