import { Button, Avatar } from 'antd'
import React, { useState, useContext } from 'react';
import { titleStyle, paragraphStyle, componentStyle } from '../style';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import EdenWebContext from  '../contexts/index'

function FormsDownload(){

    const { Paragraph } = Typography;
    const { getPresignedUrl_for_HIPAA, getPresignedUrl_for_New_Patient_Form } = useContext(EdenWebContext);

    const getHIPPAForm = (event) => {
        getPresignedUrl_for_HIPAA();
    }

    const getNewPatientForm = (event) => {
        getPresignedUrl_for_New_Patient_Form();
    }

    const buttonStyle = {
        background: "none",
        border: "none",
        fontSize: "30px",
        color:"#05202e", /* Button text color */
        padding: "0px", /* Optional: Remove padding to make the button text closer to the container edge */
    }

    return (
        <div style={{padding: "20px 0px 1px 0px", textAlign:"center"}}>

             <div style={ {paddingBottom: '20px'}}>
                <Button onClick={getNewPatientForm} style={buttonStyle}>
                    <span style={{paddingRight: '15px'}}> Download New Patient Form </span>
                    <Avatar
                        style={{
                            backgroundColor: '#05202e',
                            color: "white"
                        }}
                        icon={<ArrowRightOutlined />}
                    />
                </Button>
            </div>
            
            <div style={{paddingBottom: '20px'}}>
                <Button onClick={getHIPPAForm} style={buttonStyle}>
                    <span style={{paddingRight: '15px'}}>Download HIPAA Form </span>
                    <Avatar
                        style={{
                            backgroundColor: '#05202e',
                            color: "white"
                        }}
                        icon={<ArrowRightOutlined />}
                    />
                </Button>
            </div>
            
        </div>
    )
}

export default FormsDownload;