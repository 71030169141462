import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ConfigProvider } from 'antd';
import { EdenWebContextProvider } from './contexts/index';

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);

root.render(
    <ConfigProvider theme={{ token: { colorPrimary: '#00897B' } }}>
        <EdenWebContextProvider>
            <App /> 
        </EdenWebContextProvider>  
    </ConfigProvider>
);