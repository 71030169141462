import { Col, Row } from 'antd';
import { Typography } from 'antd';
import { titleStyle, paragraphStyle } from '../style';

function CompleteDentistry(){

    const { Paragraph } = Typography;

    return (
        <div>

            <Row>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />

                <Col xs={22} sm={20} md={18} lg={16} xl={14}>
                    <Typography style={paragraphStyle}>
                        <Paragraph style={titleStyle}>
                            What is Complete Dentitry?
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            The Ultimate Goal of all dental treatment is optimum oral health which can be maintained for a life time.
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            The Concept of Complete Dentistry addresses the harmony of our entire masticatory system (all parts that are involved in chewing), which is critical in achieving optimum oral health and maintaining it for a life time.
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            Complete Dentistry starts with an understanding of all parts of the masticatory system,

                            <div style={ { paddingLeft: "20px", paddingTop: "20px" }}>
                                <ol >
                                    <li>Temporomandibular Joint (TMJ)</li>
                                    <li>Teeth and Bite</li>
                                    <li>Muscles of Mastication</li>
                                    <li>Surrounding Tissues</li>
                                </ol>
                            </div>
                        
                            to work together in harmony to achieve an optimum oral health that lasts.
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            From that understanding, careful examination will isolate all factors that can cause diseases, disharmony or, instability. Then, the best treatments will be rendered to achieve an optimum oral heath that is predictable, comfortable, beautiful and produces lasting result.
                        </Paragraph>
                    </Typography>
                </Col>
                
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
            </Row>

        </div>
    )
}

export default CompleteDentistry;