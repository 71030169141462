import { Col, Row, Typography  } from 'antd';
import { titleStyle, paragraphStyle, subTitleStyle, containerStyle } from '../../style';

function AfterCareOcclusalGuard() {
    const { Paragraph } = Typography;

    return (
        <div>
            <Row style={containerStyle}>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
                <Col xs={22} sm={20} md={18} lg={16} xl={14}>
                    <Typography style={paragraphStyle}>
                        <Paragraph style={titleStyle}>
                            HOME CARE INSTRUCTION AFTER OCCLUSAL GUARD                       
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>1. CLEANING: </span>                 
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <ul>
                                <li>After removal, clean the appliance with a soft toothbrush and toothpaste, or with antibacterial hand soap. </li>
                                <li>For a soft guard, be gentle on the soft inner lining, avoiding abrasive brushing. Avoid using harsh brushes like denture toothbrushes.</li>
                                <li><b>Never</b> soak the appliance in mouthwash or alcohol-based rinses, bleach, or harsh chemicals.</li>
                                <li>Avoid boiling the appliance, as it can distort its shape.</li>
                                <li>Soak the appliance in denture cleaner for a maximum of 20 minutes, once a week.</li>
                            </ul>
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>2. STORE: </span>                 
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <ul>
                                <li>Keep the appliance away from animals to prevent chewing.</li>
                                <li>Store your night guard in a dry case when not in use.</li>
                                <li><b>Never</b> wrap it in a tissue. It will ended up in a gabage can.</li>
                            </ul>
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>3. FIT/BITE: </span>                 
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <ul>
                                <li>If fitting/biting feels uneven, please give us a call at 201-224-6401 for  simple adjustment.</li>
                            </ul>
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>4. REPLACEMENT: </span>                 
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <ul>
                                <li>Over time, the appliance can be discolored due to age.</li>
                                <li>Bring the guard in for your annual exam to ensure it provides adequate protection.</li>
                                <li>Understand that eventual replacement of the appliance will be necessary depending on the severity of grinding and regular wear and tear. Typically, the appliance lasts anywhere from 3-5 years with normal use. </li>
                            </ul>
                        </Paragraph>

                    </Typography>
                </Col>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
            </Row>
        </div>
    )
}

export default AfterCareOcclusalGuard;