import React from 'react';
import { Row, Col, Typography, Card, Flex, Form, Input, Button, Select, DatePicker } from 'antd';
import { paragraphStyle } from '../style';


const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 14,
    },
};

const { Option } = Select;

function FormPage() {

    const onFinish = (values) => {
      console.log('Form values: ', values);
    };

    const { Title, Paragraph } = Typography;
    const [form] = Form.useForm();
  
    return (
      <div style={{ marginTop: "70px" }}>

        <Row>
            <Col xs={0} sm={3} md={3} lg={4} xl={5} />

            <Col xs={24} sm={20} md={18} lg={16} xl={14}>

            <h1 style={{ paddingLeft: "70px" }}>REQUEST AN APPOINTMENT</h1>
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{
                    maxWidth: 800,
                    paddingTop: "30px"
                }}
            >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your name.'
                        }
                        ]}
                    >
                        <Input placeholder="Name" />
                    </Form.Item>

                    <Form.Item
                        name="phone"
                        label="Phone"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your phone number.'
                            },
                        ]}
                    >
                        <Input placeholder="Phone Number" />
                    </Form.Item>

                    <Form.Item 
                        name="location" 
                        label="Location" 
                        rules={[
                            { 
                                required: true ,
                                message: 'Please a preferred location.'
                            }]}
                    >
                        <Select
                            placeholder="Select a preferred location"
                            allowClear
                        >
                        <Option value="Nanuet, NY">Nanuet, NY</Option>
                        <Option value="Edgewater , NJ">Edgewater , NJ</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            {
                                type: 'email',
                            },
                        ]}
                    >
                        <Input placeholder="Email"/>
                    </Form.Item>

                    <Form.Item
                        name="dateTime"
                        label="Prefered Date"
                    >
                        <DatePicker />
                    </Form.Item>
                    
                </Form>
            
            </Col>

            <Col xs={0} sm={3} md={3} lg={4} xl={5} />
        </Row>

       
      </div>
    );
  };
  
  export default FormPage;