import { Col, Row } from 'antd';
import TopMenu from "./menu";
//import EdenDentalLogoImage from '../images/logo_edendental.jpg';
import EdenDentalLogoImage from '../images/edenDentalLogo.png';

function EdenHeader() {


    return (
        <header>
            <Row >
                <Col flex="0 1 400px" style={{maxWidth: '80%', maxHeight: '80%', paddingLeft: '100px'}}>
                    <a href="/">
                        <img src={EdenDentalLogoImage} alt="edenDental" />
                    </a>
                </Col>
                <Col flex="1 1 100px">
                </Col>
                <Col flex="1 1 400px">
                    <TopMenu />
                </Col>
            </Row>   
        </header>  
    )
}

export default EdenHeader;