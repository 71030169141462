
import { Row, Col, Image, Typography, Card  } from 'antd';
import { paragraphStyle } from '../style';
import dr_Lee from '../images/dr_Lee.jpg'


function MeetDrLee() {

    const pageStyle = {
        backgroundColor: 'white',
        color: 'white'
    }

    const imageColStyle = {
        padding: '50px 50px 50px 85px'
    }

    const profileColStyle = {
        padding: '50px',
    }

    const linkStyle = {
        color: '#009688', // Set your desired link color
        textDecoration: 'none', // Optional: Remove underline from the link
    };

    const { Title, Paragraph } = Typography;
    const { Meta } = Card;

    return (
        <Row style={pageStyle}>
            <Col flex="0 1 300px" style={imageColStyle}>
                <div style={{paddingTop: '30px', paddingLeft: '20px'}}>
                    <Image
                        preview={false}
                        style={{ borderRadius: '5px' }} 
                        width={300}
                        src={dr_Lee}
                    />
                </div>
            </Col>

            <Col flex="1 1 300px" style={profileColStyle}>

                <Title level={1} style={{
                    color: '#05202e',
                    fontFamily: "Quattrocento",
                    letterSpacing: "1px",
                    lineHeight: "1em"
                }}>Sunghyuk Lee, DDS</Title>

                <Paragraph style={paragraphStyle}>
                    Dr. Lee is a graduate of <a style={linkStyle} href="https://dental.nyu.edu/">New York University College of Dentistry</a>. He finished his General Practice Residency at the <a style={linkStyle} href="https://einsteinmed.edu/">Albert Einstein College of Medicine</a> at Montefiore Medical Center. Following his residency, he returned to NYU to complete a two-year specialty course on surgical and prosthetic treatment of dental implants.
                </Paragraph>

                <Paragraph style={paragraphStyle}>
                            Dr. Lee maintains the highest level of expertise by attending many continuing education classes on the most current systems and techniques of dental care. He is dedicated to staying in-step with the latest methods and procedures, and vows to never stop learning.
                </Paragraph>

                <Paragraph style={paragraphStyle}>
                    Dr. Lee and his wife Angela have two beautiful children, Andy and Michelle, who he loves spending time with. He also enjoys fishing and taking summer dental mission trips, where he travels to countries in South America and treats local people for their dental needs.
                </Paragraph>

                <Paragraph style={paragraphStyle}>
                    <ul >
                        <li>New York University College of Dentistry (D.D.S)</li>
                        <li>Albert Einstein College of Medicine / Montefiore Medical Center (General Practice Residency)</li>
                        <li>New York University College of Dentistry :</li>
                        <ul style={{marginLeft: "20px"}}>
                            <li>Implantology: Surgical and Prosthetic Treatment</li>
                            <li>Advances and Innovations in Sinus Lift Procedures and Augmentation Procedures</li>
                        </ul>
                        <li>The Dawson Academy</li>
                        <ul style={{marginLeft: "20px"}}>
                            <li>Functional Occlusion - From TMJ to Smile Design</li>
                            <li>Achieving Predictable Esthetic Results </li>
                            <li>EXAMINATION AND RECORDS </li>
                            <li>The Art and Science of Equilibration </li>
                            <li>TMD Patients: Diagnosis and Treatment </li>
                            <li>International Airway Symposium </li>
                            <li>Airway & Breathing Related Disorders-Evaluation, Diagnosis & Treatment </li>
                        </ul>
                        <li>Pikos Institute</li>
                        <ul style={{marginLeft: "20px"}}>
                            <li>Pikos Urban Symposium</li>
                            <li>Vertical and Horizontal Ridge Augmentation with Titanium Mesh/rhBMP-2 Particulated Bone Graft</li>
                            <li>Vertical Ridge Augmentation with Titanium Reinforced Membrane and Particulated Bone Graft</li>
                            <li>Sinus Grafting for Implant Reconstruction</li>
                        </ul>
                        <li>Oral Reconstruction Foundation</li>
                        <ul style={{marginLeft: "20px"}}>
                            <li>Introduction to Socket Grafting</li>
                            <li>Treatment Protocols to Minimize Implant Failures in Medically Compromised Patients</li>
                            <li>Demystifying Digital Dentistry and Making it Work for You</li>
                            <li>Modern Dental Implant Concepts</li>
                            <li>Techniques for Soft Tissue Preservation with Immediate Implant Placement and   Temporization Techniques: Hands-on Course</li>
                            <li>L-PRF: Techniques & Applications for Dental Implant Cases</li>
                        </ul>
                        <li>American Academy of Facial Esthetics</li>
                        <ul style={{marginLeft: "20px"}}>
                            <li>Botox and Dermal Filler Hands-On Training</li>
                        </ul>
                        <li>Ruiz Dental Seminars</li>
                        <ul style={{marginLeft: "20px"}}>
                            <li>Supra-gingival Dentistry: Easy, Predictable, Porcelain Veneers </li>
                        </ul>
                        <li>Align Technology, Inc.</li>
                        <ul style={{marginLeft: "20px"}}>
                            <li>Invisalign Certified </li>
                        </ul>
                        <li>Sprint Ray</li>
                        <ul style={{marginLeft: "20px"}}>
                            <li>3D printing Definitive Ceramic Crowns</li>
                        </ul>
                    </ul>

                </Paragraph>

            </Col>
            
        </Row>
    );
};

export default MeetDrLee;
