import { Col, Row, Typography  } from 'antd';
import { titleStyle, paragraphStyle, subTitleStyle, containerStyle } from '../../style';

function AfterCareFilling() {
    const { Paragraph } = Typography;

    return (
        <div>
            <Row style={containerStyle}>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
                <Col xs={22} sm={20} md={18} lg={16} xl={14}>
                    <Typography style={paragraphStyle}>
                        <Paragraph style={titleStyle}>
                            HOME CARE INSTRUCTION AFTER – FILLINGS                        
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>1. EATING: </span> Wait until the anesthesia wears off before eating. Be mindful to avoid accidentally biting your tongue, lips, or cheeks due to numbness.                          
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>2. HIGH/TALL BITE, OFF-BALANCED BITE: </span> If you experience any issues with your bite, please contact us at 201-224-6401 for necessary adjustments.                         
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>3. SENSITIVITY TO HOT AND COLD: </span> It's normal to experience sensitivity to hot and cold following the procedure. Symptoms typically improve within a few days, but in some cases, it may take a few weeks. As long as the sensitivity diminishes over time, there's usually no cause for concern.                          
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>4. GUM DISCOMFORT: </span> Your gums may feel tender after the procedure, and the injection site might be sore. Rinsing your mouth 2-3 times a day with 1 teaspoon of warm salt water can help ease discomfort. Tylenol or Advil can also be effective in alleviating tenderness.                           
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>5. PAIN MEDS: </span> Take an anti-inflammatory pain reliever if needed (e.g., Tylemol/Advil).                          
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>6. JAW PAIN: </span> Apply ice packs or cold compresses to the muscles and joints for 5 minutes, followed by a hot compress for 15 minutes to the same areas. This can be repeated several times throughout the day.                         
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>7. PERSISTENT/SEVERE SENSITIVITY TO HOT/COLD/THROBBING PAIN: </span> A deeper cavity can lead to severe/throbbing pain inside the tooth. Please give us a call at 201-224-6401 for further evaluation.                         
                        </Paragraph>
                    </Typography>
                </Col>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
            </Row>
        </div>
    )
}

export default AfterCareFilling;