import { Col, Row } from 'antd';
import { Collapse, List, Card } from 'antd';
import { Typography } from 'antd';
import { titleStyle, paragraphStyle, componentStyle } from '../style';

function FAQ() {

    const { Paragraph } = Typography;

    const listStyle = {
        listStyleType: 'square',
        color: 'black',
        marginLeft: '20px'
    }

    const badBreadthCause = [
        "Gum disease",
        "Cavities",
        "Poor oral hygiene",
        "Oral cancer",
        "Dry Mouth (xerostomia)",
        "Bacteria on the tongue"
    ]

    const whitening = [
        "Teeth whitening procedures are not covered by most dental insurance plans.",
        "Tooth colored fillings, crowns, bridges and veneers will not lighten when your teeth are bleached.",
        "Teeth whitening does not produce the same results on all types of teeth discoloration. Someone with yellow tinted teeth would probably have great results with bleaching, while someone with gray tinted teeth might be disappointed because gray tinted teeth don't bleach as well.",     
        "Tooth sensitivity and gum irritation are common side effects of teeth whitening treatments.",
        "If you have any cavities, they should be filled before you have your teeth whitened.",         
        "Touch ups are usually needed to maintain your desired shade, depending upon habits such as smoking, drinking coffee and tea and eating certain foods, which will stain your teeth."
    ]

    const causeList = (
        badBreadthCause.map((item, index) => {
            return (
                <li key={index}>{item}</li>
            )
        })
    )

    const whiteningList = (
        whitening.map((item, index) => {
            return (
                <li key={index}>{item}</li>
            )
        })  
    )
    
    return (
        <div>
            <Row style={{paddingTop:"50px"}}>
                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
                <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                    <Typography>
                        <Paragraph style={titleStyle}>
                            FAQ (Frequently Asked Questions)
                        </Paragraph>
                    </Typography>
                </Col>
                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
            </Row>

            <Row style={ {paddingBottom: "30px"} }>
                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
                <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                    <Card title="A missing tooth? Dental Implants could be the answer!" style={paragraphStyle}>
                        Losing a tooth by accident or by extraction is not the end of the matter. 
                        Aside from the obvious cosmetic problem, the changed positions of these teeth can 
                        lead to severe bite problems causing jaw pain and headaches. Missing teeth should be replaced to keep 
                        other teeth in their normal position and this can be done in our office.
                    </Card>
                </Col>
                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
            </Row>

            <Row style={ {paddingBottom: "30px"} }>

                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
                <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                    <Card title="Pregnancy Gingivitis - What is Pregnancy Gingivitis?" style={paragraphStyle}>
                        According to the American Academy of Periodontology, about 50% of women experience pregnancy gingivitis. 
                        This condition is most common between the second to eighth month of pregnancy. If you experience the symptoms 
                        of pregnancy gingivitis, be sure to visit your dentist to see if you need more frequent dental cleanings or other 
                        treatment.
                    </Card>
                </Col>
                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
            </Row>

            <Row style={ {paddingBottom: "30px"} }>

                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
                <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                    <Card title="Do you follow current guidelines for sterilization and safety?" style={paragraphStyle}>
                        Yes. We are up-to-date on all sterilization and disinfection techniques. 
                        We follow all recommendations and requirements of the governing bodies of State of New Jersey, 
                        including OSHA guidelines. Our staff works hard for providing a safe, clean environment for our 
                        patients and our dental team.
                    </Card>
                </Col>
                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
            </Row>

            <Row style={ {paddingBottom: "30px"} }>
                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
                <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                    <Card title="Do you use Digital Radiography?" style={paragraphStyle}>
                        Yes. Digital X-rays are captured by a sensor, not film. 
                        The images are sharper and can be manipulated for better diagnosis. More importantly, 
                        since they require about a sixth of the radiation, patient exposure is greatly reduced.
                    </Card>
                </Col>
                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
            </Row>

            <Row style={ {paddingBottom: "30px"} }>
                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
                <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                    <Card title="What Causes Bad Breath?" style={paragraphStyle}>
                        According to dental studies, about 85% of people with persistent bad breath (also known as halitosis) 
                        have a dental condition that is to blame. These conditions could be one or more of the following:
                        <ul id="disc">
                            {causeList}
                        </ul>
                    </Card>
                </Col>
                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
            </Row>

            <Row style={ {paddingBottom: "30px"} }>
                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
                <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                    <Card title="What is Teeth Whitening and am I a good candidate for it?" style={paragraphStyle}>
                        Teeth whitening, also known as teeth bleaching, has become extremely popular over the last 
                        several years. It's a great way to improve your appearance, it's reasonably priced, non-surgical 
                        and non-invasive.
                        <br />
                        <br />
                        As with everything else, teeth whitening isn't perfect and it isn't for everyone.
                        Before you decide to have your teeth whitened, you should talk with your dentist. 
                        Your dentist can determine if you are a good candidate for teeth whitening.

                        <br />
                        <br />

                        Facts About Whitening:
                        <ol>
                            {whiteningList}
                        </ol>
                    </Card>
                </Col>
                <Col xs={0} sm={3} md={3} lg={4} xl={5} />
            </Row>
        </div>
    )
}

export default FAQ;