
import React, { useState, useContext } from 'react';
import { Row, Col, Button, Modal, Avatar, Form, Input, Select, DatePicker  } from 'antd';
import {  useNavigate } from 'react-router-dom';
import NJOffice from '../../components/NJOffice';
import NYOffice from '../../components/NYOffice';
import { ArrowRightOutlined } from '@ant-design/icons';
import connectUsImage from '../../images/connectUs.jpg'
import EdenWebContext from  '../../contexts/index'

const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 16,
    },
};

const { Option } = Select;

function NJOfficePage() {

    const { post_request_information } = useContext(EdenWebContext);

    const [requestInfoSubmitted, setRequestInfoSubmitted] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [form] = Form.useForm();

    const navigate = useNavigate();

    const showModal = () => {

        navigate('/nj_office/scheduleForm', { replace: true });
        setIsModalOpen(true);
    };

    const handleOk = () => {

        form.validateFields().then((values) => {

            form.resetFields();
            setIsModalOpen(false);
            post_request_information(values)
            setRequestInfoSubmitted(true)

          }).catch((errorInfo) => {
            console.log('Validation failed:', errorInfo);
            setRequestInfoSubmitted(false)
          });
          navigate('/home', { replace: true });
    };

    const handleCancel = () => {
        form.resetFields();
        setIsModalOpen(false);
        setRequestInfoSubmitted(false)
        navigate('/nj_office', { replace: true });
    };


    const connectUsBackgroundImageStyle = {
        backgroundImage: `url(${connectUsImage})`,
        backgroundSize: 'cover', // Optional: Adjust the background image size ('cover' will cover the entire element)
        backgroundRepeat: 'no-repeat', // Optional: Prevent background image from repeating
        width: '100%',
        height: '100%',
        backgroundPosition: 'center',
    };

    const connectTextStyle = {
        color: "white",
        fontSize: "30px",
        textAlign: "center",
        paddingTop: "300px",
        paddingBottom: "30px"
    }

    const modalStyle = {
        color: "white",
        fontSize: "80px",
        textAlign: "center",
        //paddingTop: "5px"
    }

    const modelButtonStyle = {
        background: "none",
        border: "none",
        fontSize: "30px",
        color:"#E65100", /* Button text color */
        padding: "0px", /* Optional: Remove padding to make the button text closer to the container edge */
    }

    const okText = requestInfoSubmitted? "Close" : "Send Request"

    return (
        <div style={{backgroundColor: "white"}}>
            <Row>
                <Col span={24} style={connectUsBackgroundImageStyle}>
                <Row>
                        <Col span={24} style={modalStyle}>
                            <Button style={modelButtonStyle} onClick={showModal}>
                                <span style={{paddingRight: '15px'}}>schdule an appointment</span> 
                                <Avatar
                                    style={{
                                        backgroundColor: 'white',
                                        color: "#f86d4c"
                                    }}
                                    icon={<ArrowRightOutlined />}
                                />
                            </Button>

                                <Modal 
                                    title="REQUEST AN APPOINTMENT" 
                                    open={isModalOpen} 
                                    onOk={handleOk} 
                                    onCancel={handleCancel}
                                    okText="Submit"
                                >    
                                    <Form
                                        {...layout}
                                        form={form}
                                        name="control-hooks"
                                        style={{
                                            maxWidth: 800,
                                            paddingTop: "30px"
                                        }}
                                        initialValues={{ location: 'Edgewater , NJ' }}
                                    >
                                        <Form.Item
                                            name="name"
                                            label="Name"
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please input your name.'
                                            }
                                            ]}
                                        >
                                            <Input placeholder="Name" />
                                        </Form.Item>

                                        <Form.Item
                                            name="phone"
                                            label="Phone"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your phone number.'
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Phone Number" />
                                        </Form.Item>

                                        <Form.Item 
                                            name="location" 
                                            label="Location" 
                                            rules={[
                                                { 
                                                    required: true ,
                                                    message: 'Please a preferred location.'
                                                }]}
                                        >
                                            <Select
                                                placeholder="Select a preferred location"
                                                allowClear
                                            >
                                            <Option value="Nanuet, NY" >Nanuet, NY</Option>
                                            <Option value="Edgewater , NJ">Edgewater , NJ</Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name="email"
                                            label="Email"
                                            rules={[
                                                {
                                                  type: 'email',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Email"/>
                                        </Form.Item>

                                        <Form.Item
                                            name="dateTime"
                                            label="Prefered Date"
                                        >
                                            <DatePicker />
                                        </Form.Item>
                                        
                                    </Form>

                                </Modal> 
                        </Col>
                    </Row>
                    <Row >
                        <Col span={24} style={connectTextStyle}>
                            connect with us
                        </Col>
                    </Row>
                </Col>
            </Row>

            <NJOffice />
        </div>
    );
};

export default NJOfficePage;