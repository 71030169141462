import { Col, Row } from 'antd';
import { Typography, Avatar } from 'antd';
import facebook_avatar from '../images/facebook_avatar.png'
import facebook_avatar_v2 from '../images/avatar_facebook_v2.gif'

function EdenFooter() {

    const style = {
        fontFamily: 'sarabun',
        fontSize: '12px',
        padding: '150px',
        background:"#bbcd37", 
        height:"100px", 
    }

    const facebookProfileUrl = "https://www.facebook.com/eden.dental.3";
    const { Text, Link, Paragraph } = Typography;

    const handleAvatarClick = () => {
        // Navigate to the Facebook profile when the Avatar is clicked
        //window.location.href = facebookProfileUrl;
        window.open(facebookProfileUrl, '_blank');

      };

    return (
        <footer>
            <Row>
                <Col flex="0 1 300px">
                    <Paragraph  style={{color: "white", fontSize:"14px"}}> 
                        NJ Office: <br></br>
                        986 River Road <br></br>
                        Edgewater, NJ 07020 <br></br>
                        Tel: <a href="tel:+12012246401">201-224-6401</a> <br></br>
                        Email: edendentalnj@gmail.com
                    </Paragraph>  
                </Col>
                <Col flex="1 1 300px">
                    <Paragraph style={{color: "white", fontSize:"14px"}}> 
                        NY Office: <br></br>
                        55 Old Turnpike Road, Suite #501 <br></br>
                        Nanuet, NY 10954 <br></br>
                        Tel: <a href="tel:+18456232929">845-623-2929/8282</a> <br></br>
                        Email: edendentalny@gmail.com
                    </Paragraph> 
                </Col>
                <Col>
                    <Row style={{align: "right"}}>
                        <a href={facebookProfileUrl} target="_blank" rel="noopener noreferrer">
                            {/* Wrap the Avatar in an anchor tag */}
                            <Avatar size={35} src={facebook_avatar_v2} onClick={handleAvatarClick} />
                        </a>
                    </Row> 
                </Col>
            </Row>   
            <Row style={{color: "white", fontSize: "11px"}}>
                <p>&copy; 2023 Eden Dental NJ, INC. All rights reserved.</p>
            </Row> 
        </footer>
    )
}

export default EdenFooter;
