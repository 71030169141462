import { Col, Row, Typography  } from 'antd';
import { titleStyle, paragraphStyle, subTitleStyle, containerStyle } from '../../style';

function BeforeAfterSurgery() {
    const { Paragraph } = Typography;

    return (
        <div>
            <Row style={containerStyle}>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
                <Col xs={22} sm={20} md={18} lg={16} xl={14}>
                    <Typography style={paragraphStyle}>
                        <Paragraph style={titleStyle}>
                            PREPARING FOR SURGERY – IMPLANT / TEETH EXTRACTION / BONE GRAFTING/ GUM SURGERY                         
                        </Paragraph>
                    </Typography>
                    <Paragraph style={paragraphStyle}>
                        <ol>
                            <li> Avoid using tobacco and alcohol as they can slow down healing and increase the risks of complications post-surgery. </li>
                            <li> Inform your surgeon/dentist about any medications you are taking, as some can affect the surgical procedure and recovery. Certain medical conditions may require clearance from your physician before dental surgery, so it's crucial to discuss your medical history with your dentist beforehand. </li>
                            <li> Maintain clean teeth by scheduling a cleaning appointment before surgery or practicing good oral hygiene at home. The incision area can be prone to infection from oral bacteria. </li>
                            <li> If prescribed antibiotics, start taking them 2 days before surgery so that the surgery day aligns with your third day of medication. Consider taking probiotic supplements alongside antibiotics.</li>
                            <li> Prepare by arranging soft foods, ice packs, prescriptions, and transportation in advance of the surgery(when recommended) to ensure you can head straight home to rest comfortably after the procedure. </li>
                        </ol>
                    </Paragraph>
                    
                    <Typography style={paragraphStyle}>
                        <Paragraph style={titleStyle}>
                            Post Extraction/Surgery Instructions                        
                        </Paragraph>
                    </Typography>


            
                    <Paragraph style={subTitleStyle}>
                        1. ANESTHESIA
                    </Paragraph>

                    <Paragraph style={paragraphStyle}>
                    The feeling of numbness will begin to wear off in 30 minutes to 4 hours. Until that time, avoid all hot foods or liquids, and do not chew. This is to prevent accidentally burning or biting the lips, cheeks, inside of your mouth, or tongue until feeling has returned. ANY CONCERNS ABOUT PROLONGED NUMBNESS, PLEASE CALL OUR OFFICE.
                    </Paragraph>

                    <Paragraph style={subTitleStyle}>
                        2. GAUZE PACK
                    </Paragraph>

                    <Paragraph style={paragraphStyle}>
                    Fold the gauze into a small pack and place over the extraction/surgical site. There should be firm pressure on the extraction site. Maintain firm biting pressure on the gauze for one to two hours. Change the gauze pack every 15-30 minutes.
                    </Paragraph>

                    <Paragraph style={subTitleStyle}>
                    3. BLEEDING
                    </Paragraph>

                    <Paragraph style={paragraphStyle}>
                    It is normal for the extraction site to bleed slightly or ooze blood for 12 to 24 hours following surgery.
                    </Paragraph>

                    <Paragraph style={subTitleStyle}>
                    4. ICE PACK
                    </Paragraph>

                    <Paragraph style={paragraphStyle}>
                    For the first 2-8 hours after surgery, ice packs should be applied to the outside of the face over the area of the extraction site. The ice pack should be held in place for 15 minutes or so, and then removed for 15 minutes. This will help reduce discomfort and swelling.
                    </Paragraph>

                    <Paragraph style={subTitleStyle}>
                    5. MEDICATIONS
                    </Paragraph>

                    <Paragraph style={paragraphStyle}>
                    DO NOT TAKE ASPIRIN PRODUCTS due to the possible increase in bleeding potential. If prescription medications were prescribed, please follow label instructions carefully. For most extractions, a non-aspirin over the counter pain medication will provide good pain relief. Do not take more than the manufacturer or doctor recommended dosage of any over the counter or prescription medication(s). If you experience any unexpected side effects from the medication(s), please discontinue taking them, and call our office. 
                    </Paragraph>

                    <Paragraph style={paragraphStyle}>
                    FOR SERIOUS SIDE EFFECTS OR ALLERGIC REACTIONS SEEK MEDICAL CARE IMMEDIATELY.       
                    </Paragraph>

                    <Paragraph style={paragraphStyle}>
                    For mild pain: Take Ibuprofen (Advil/ Motrin) 600 mg or Acetaminophen (Tylenol) 1000 mg every 8 hours for 2 days.
                    </Paragraph>

                    <Paragraph style={paragraphStyle}>
                    For severe pain:
                    <ol>
                        <li>Take <b>Ibuprofen (Advil/Motrin) 800mg</b>, wait 4 hours then take <b>Acetaminophen (Tylenol) 1000mg </b></li>
                        <li>Wait 4 hours then take <b>Advil/Motrin 800mg</b>, wait 4 hours and take <b>Tylenol 1000mg</b></li>
                        <li>Continue above protocol for 2 days.</li>
                        <li>Prescribed controlled pain medication( ie, Vicodine) should be taken at night if needed.</li>
                    </ol>
                    </Paragraph>

                    <Paragraph style={subTitleStyle}>
                    6. DIET
                    </Paragraph>

                    <Paragraph style={paragraphStyle}>
                    A liquid or soft diet should be adhered to for the first 12-24 hours. It is important to drink plenty of liquids for the first day or two following surgery.<b>Avoid the use of a straw</b> as it may dislodge the blood clot that is forming in the extraction site.
                    </Paragraph>

                    <Paragraph style={subTitleStyle}>
                    7. ORAL HYGIENE
                    </Paragraph>

                    <Paragraph style={paragraphStyle}>
                    Clean the rest of your mouths as usual, however avoid bumping or brushing the extraction/ surgical site. DO NOT SWISH YOUR MOUTH for the first 24 hours following surgery. DO NOT SMOKE for the first 3 days following surgery.
                    </Paragraph>

                    <Paragraph style={subTitleStyle}>
                    8. POSSIBLE COMPLICATIONS
                    </Paragraph>

                    <Paragraph style={paragraphStyle}>
                    <ul>
                        <li>Dry Socket. This is sometimes a problem after surgery. The symptoms associated with dry socket are constant moderate to severe pain, bad taste, putrid odor, and poor clot formation at the surgical site.IF YOU THINK YOU HAVE ANY OF THESE SYMPTOMS CALL OUR OFFICE IMMEDIATELY.</li>
                        <li><b>Fever</b>. Monitor your temperature for the first 24-48 hours. Any elevated temperature should be reported to our office. </li>
                        <li>Swelling & Bruising. Some swelling & bruising during the first 24-48 hours can be expected.</li>
                    </ul>
                    </Paragraph>
                
                </Col>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
            </Row>
        </div>
    )
}

export default BeforeAfterSurgery;