import { Col, Row, Typography  } from 'antd';
import { titleStyle, paragraphStyle, subTitleStyle, containerStyle } from '../../style';

function AfterCareDenture() {
    const { Paragraph } = Typography;

    return (
        <div>
            <Row style={containerStyle}>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
                <Col xs={22} sm={20} md={18} lg={16} xl={14}>
                    <Typography style={paragraphStyle}>
                        <Paragraph style={titleStyle}>
                            HOME CARE INSTRUCTION AFTER – DENTURE                       
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>1. BREAK-IN PERIOD: </span> It's common for new dentures to require a break-in period before feeling comfortable. Initially, you may feel awkward with your new dentures. The duration of this period varies for each patient, depending on their ability, determination, and perseverance in adapting to the new denture.                       
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>2. LOOSENESS: </span> If your dentures were placed immediately after tooth removal, they may feel slightly loose due to gum shrinkage during the healing process, which typically takes three to six months. During this time, temporary liners can be used to maintain a relatively snug fit. Denture adhesives can also help. Once healing is complete, a permanent liner will be placed for a better fit.                         
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>3. SORE SPOTS: </span> Soreness from uneven pressure on the gums can occur and it is common. If any sore spots develop, please contact our office to schedule an adjustment appointment. Wearing the dentures for 3-6 hours before the adjustment appointment allows for precise detection of the problem areas.                       
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>4. EATING: </span> Begin with soft foods that are easy to chew, taking small bites and chewing slowly. Distribute food evenly on both sides of your mouth. Cutting up foods that require extensive chewing can make eating easier. While there is a learning curve, you'll eventually be able to expand your diet to include a variety of foods.            
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>5. SPEAKING: </span> Patience is necessary when learning to speak with your new dentures. Reading out loud regularly can expedite the adjustment process.                       
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>6. DAILY CLEANING: </span> Rinse your full or partial dentures under warm water after eating to remove food debris, avoiding hot water to prevent warping. Brush your dentures daily with a soft toothbrush or denture brush using warm water, mild soap, or cleaning paste. When not wearing your dentures, soak them in water or denture solution to prevent acrylic from drying out. It's essential to remove dentures at night to allow your gums to breathe, reducing the risk of irritation and infection. Clean the tissues inside your mouth with a damp washcloth or soft toothbrush and warm water. Daily rinsing with lukewarm saltwater can also help maintain gum health.  
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>7. DENTURE MAINTENANCE PRODUCTS: </span> These products are available at local pharmacies such as Walgreens, CVS, etc.
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <ul>
                                <li>DENTURE CLEANER: Polident tablets, Efferdent tablets, etc. </li>
                                <li>DENTURE ADHESIVE: Fixodent, Poligrip, Cushion grip, Effer grip, etc. </li>
                            </ul>
                        </Paragraph>

                        <Paragraph style={titleStyle}>
                            HOME CARE INSTRUCTION AFTER - PLACEMENT OF AN IMMEDIATE DENTURE FOLLOWED BY EXTRACTIONS ON THE SAME DAY                      
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>1. FIRST 24 - 48 HOURS: </span> Refrain from removing the denture until the morning after your extractions. Some blood oozing around the denture is typical and not cause for alarm. Your immediate denture serves to replace missing teeth, protect the surgical site, and manage swelling and bleeding. It must remain in place to be effective. The morning after the procedure, wash your hands and gently remove the denture using both hands while standing over the sink. Rinse it under cold water, then scrub the inside and outside with a denture brush and cold water only—no soap, cleaners, or toothpaste. Carefully rinse your mouth with warm salt water and reinsert the denture. Clean any remaining natural teeth as usual. Repeat this process for the next two mornings.                    
                        </Paragraph>
                        
                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>2. FOURTH DAY AFTER EXTRACTION: </span> Remove the denture at bedtime, rinse and scrub it, then rinse your mouth again with warm salt water. Place the denture in a container filled with clean, cold water and leave it overnight. Rinse it again in the morning before reinserting it. Continue warm salt water rinses for at least 7-10 days. (Avoid denture cleaning tablets or adhesive until advised).                        
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>3. APPOINTMENT WITH YOUR DENTIST: </span> If you haven't scheduled a follow-up appointment within a week, please call us at 201-224-6401 to arrange one.                        
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>4. PAIN/MEDICATIONS: </span> Follow all prescribed medications from your surgeon and physician. Ibuprofen (e.g., Advil, Motrin) is effective; take 3 tablets every 8 hours for the next 3 days and take 2 tabs as needed after 3 days. For those unable to take ibuprofen, acetaminophen (e.g., Tylenol) can be substituted, though it lacks anti-inflammatory properties. Avoid aspirin and aspirin-containing products as they may increase bleeding. Alternating ibuprofen and acetaminophen may also be recommended. (Take 3 Advil initially, then 4 hours later, take 2 Tylenols. After another 4 hours, take 3 Advil again. Repeat this regimen as necessary.                       
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>5. SWELLING: </span> Swelling is normal after oral surgery and typically peaks 2-3 days post-surgery, gradually diminishing over 4-7 days. Apply an ice bag to the face for 15-20 minutes, then remove for 15-20 minutes to reduce swelling.                      
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>6. EATING/ACTIVITIES: </span> Avoid spitting, rinsing, smoking, or using a straw after extractions to prevent dislodging necessary blood clots. Stick to soft foods like soups, yogurt, pasta, eggs, mashed potatoes, bananas, and smoothies for the first few days, avoiding hard or crunchy foods. Stay hydrated with water but avoid a liquid diet. Limit strenuous activity for the first 24-48 hours, gradually resuming normal activities as tolerated 2-3 days post-surgery.         
                        </Paragraph>

                        <Paragraph style={paragraphStyle}>
                            <span style={subTitleStyle}>7. SORE SPOTS, UNEVEN BITE: </span> Contact us at 201-224-6401 for simple denture adjustment if you experience sore spots or an uneven bite.                      
                        </Paragraph>

                    </Typography>
                </Col>
                <Col xs={1} sm={3} md={3} lg={4} xl={5} />
            </Row>
        </div>
    )
}

export default AfterCareDenture;